import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from './Auth0Provider';
import { envConf } from './envConf';
import { useTenantOverride } from './TenantOverrideProvider';
import { Spin } from 'antd';
import { sessionId } from './App';
import { createSUID } from './createSUID';
import { TypedTypePolicies } from './generated/apolloClientHelpers';
import { isObject } from './services/isObject';

interface Headers {
  authorization: string;
  'x-hw-tenant-id-override'?: string;
  'x-hw-session-id': string;
  'x-hw-transaction-id': string;
  'x-hw-referrer': string;
}

export const AuthorizedApolloProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { getTokenSilently, isAuthenticated, logout } = useAuth0();
  const { tenantId: tenantOverride, isOverridden } = useTenantOverride();

  const authLink = setContext(async () => {
    try {
      const token = await getTokenSilently();

      const headers: Headers = {
        authorization: `Bearer ${token}`,
        'x-hw-session-id': sessionId,
        'x-hw-transaction-id': createSUID(),
        'x-hw-referrer': window.location.href,
      };

      if (isOverridden && tenantOverride) {
        headers['x-hw-tenant-id-override'] = tenantOverride;
      }

      return {
        headers,
      };
    } catch (e) {
      if (
        isObject(e) &&
        'error' in e &&
        (e.error === 'login_required' || e.error === 'invalid_grant')
      ) {
        return logout();
      }

      throw e;
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        // Todo: console log for dev only and perhaps log to sentry in non-dev
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) {
      // Todo: console log for dev only and perhaps log to sentry in non-dev
      // eslint-disable-next-line no-console
      console.error('[Network error]:', networkError);
    }
  });

  if (!isAuthenticated)
    return (
      <div className="center-content">
        <Spin size="large"></Spin>
      </div>
    );

  const cache = new InMemoryCache({
    typePolicies,
  });

  // Used for debugging of cache
  // (window as any).cache = cache;

  const apolloClient = new ApolloClient({
    name: 'howwe-client',
    version: envConf.COMMIT_SHA,
    link: ApolloLink.from([
      errorLink,
      authLink,
      createHttpLink({
        uri: envConf.API_BASE_URL + '/graphql',
        credentials: 'same-origin',
      }),
    ]),
    cache,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const typePolicies: TypedTypePolicies = {
  MitemSprint: {
    keyFields: ['teamId', 'endDate'],
  },
  TeamAccelerationMeeting: {
    keyFields: ['teamId', 'periodEndDate'],
  },
  TimeStatusMitem: {
    keyFields: ['deadline', 'mostImportantItem', ['id'] as any], // https://github.com/dotansimha/graphql-code-generator/issues/5025
  },
  SkaTimeStatus: {
    keyFields: ['deadline', 'id'],
  },
  SprintKeyActivityCommitmentAndAchievement: {
    keyFields: ['id', 'startDate', 'endDate'],
  },
  WeeklyKeyActivityCommitmentAndAchievement: {
    keyFields: ['id', 'startDate', 'endDate'],
  },
  TeamMembersResponse: {
    keyFields: ['teamId'],
  },
  TeamMember: {
    keyFields: ['teamId', 'user', ['id'] as any],
  },
  AccelerationMeetingPeriodSummary: {
    keyFields: ['teamId', 'endDate'],
  },
  ContributionWeeklyKeyActivity: {
    keyFields: ['id', 'userId'],
  },
  ContributionSprintKeyActivity: {
    keyFields: ['id', 'userId'],
  },
  InitiativeDomainId: {
    keyFields: ['teamId', 'itemId'],
  },
  InitiativeReport: {
    keyFields: ['id', 'filteredFor', ['teamId'] as any],
  },
  MigDomainId: {
    keyFields: ['itemId'],
  },
  BooleanWithTimestamp: {
    merge: true,
  },
};
