import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateInitiativeLinkToTenantDocument,
  GetInitiativesForNavigationDocument,
  ManageCompanyInitiatives_QueryDocument,
  TenantLinkTypes,
} from '../../../../../generated/graphql';
import { Btn } from '../../../../Button';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { Alert } from 'antd';
import { CompanyInitiativeTable } from './CompanyInitiativeTable';
import { InitiativeSelect } from './InitiativeSelect';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { ErrorAlert } from '../../../../ErrorAlert';
import { ManageCompanyInitiativesSkeleton } from './ManageCompanyInitiatives.skeleton';
import { LinkOutlined } from '@ant-design/icons';

export const ManageCompanyInitiatives = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(ManageCompanyInitiatives_QueryDocument, {
    fetchPolicy: 'network-only',
  });

  const { initiatives } = data.tenantInitiatives2;
  const { links } = data.listTenantLinks;

  const memoizedNavigationTree = useMemo(
    () =>
      simpleTreeBuilder(
        initiatives.filter((i) => i.metadata.archived == false)
      ),
    [initiatives]
  );

  const companyInitiatives = memoizedNavigationTree.filter((node) =>
    links.some((l) => l.domainId.itemId === node.initiative.domainId.itemId)
  );

  const otherInitiatives = memoizedNavigationTree.filter(
    (i) => !companyInitiatives.includes(i)
  );

  const [selectedInitiative, setSelectedInitiative] = useState<
    { domainId: { itemId: string; tenantId: string } } | undefined
  >(undefined);

  const [createInitiativeLinkToTenant, { loading: createLoader }] = useMutation(
    CreateInitiativeLinkToTenantDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: <strong>{t('ManageCompanyInitiatives.linkSuccess')}</strong>,
        });
        setSelectedInitiative(undefined);
      },
      onError: (error) => {
        showNotification('error', {
          message: <strong>{t('ManageCompanyInitiatives.linkFailed')}</strong>,
          description: <ErrorAlert error={error} />,
        });
      },
      refetchQueries: () => [
        ManageCompanyInitiatives_QueryDocument,
        GetInitiativesForNavigationDocument,
      ],
    }
  );

  const handleSubmit = () => {
    if (selectedInitiative)
      createInitiativeLinkToTenant({
        variables: {
          domainId: {
            itemId: selectedInitiative.domainId.itemId,
            tenantId: selectedInitiative.domainId.tenantId,
          },
          type: TenantLinkTypes.INITIATIVE,
        },
      });
  };

  return (
    <div>
      <Alert
        className="mb--xl"
        showIcon
        closable
        message={t('ManageCompanyInitiatives.helpText')}
      />
      <h3 className="mb--l">{t('ManageCompanyInitiatives.linkInitiative')}</h3>
      <div className="flx mb--xl">
        <InitiativeSelect
          placeholder={t('ManageCompanyInitiatives.selectInitiative')}
          initiatives={otherInitiatives.map((node) => node.initiative)}
          onChange={setSelectedInitiative}
          className="flx--1"
        />
        <Btn
          type="primary"
          className="ml--xl"
          icon={<LinkOutlined />}
          onClick={handleSubmit}
          loading={createLoader}
          disabled={!selectedInitiative}
        >
          {t('ManageCompanyInitiatives.linkInitiative')}
        </Btn>
      </div>
      <h3 className="mb--l">{t('ManageCompanyInitiatives.currentlyLinked')}</h3>
      <CompanyInitiativeTable
        initiatives={companyInitiatives.map((node) => node.initiative)}
      />
    </div>
  );
};

ManageCompanyInitiatives.Skeleton = ManageCompanyInitiativesSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LINK_INITIATIVE_TO_TENANT = gql`
  mutation createInitiativeLinkToTenant(
    $tenantId: ID
    $domainId: TenantLinkDomainIdInput!
    $type: TenantLinkTypes!
  ) {
    createLinkToTenant(tenantId: $tenantId, domainId: $domainId, type: $type) {
      id
    }
  }
`;

interface BaseInitiative {
  id: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
  metadata: {
    archived: boolean;
    completedAt: string | null;
    supportsInitiatives: {
      id: string;
      domainId: {
        itemId: string;
        teamId?: string | null;
        tenantId: string;
      };
    }[];
  };
}

export interface SimpleTreeNode<T extends BaseInitiative> {
  id: string;
  initiative: T;
  children?: SimpleTreeNode<T>[];
}

export const simpleTreeBuilder = <T extends BaseInitiative>(
  initiatives: T[],
  parentId?: string
): SimpleTreeNode<T>[] => {
  const result: SimpleTreeNode<T>[] = [];

  // Filter out initiatives that are children of the parent initiative
  const filteredInitiatives = parentId
    ? initiatives.filter((i) =>
        i.metadata.supportsInitiatives.some(
          (s) => s.domainId.itemId === parentId
        )
      )
    : initiatives.filter((i) => i.metadata.supportsInitiatives.length === 0);

  filteredInitiatives.forEach((initiative) => {
    const node: SimpleTreeNode<T> = {
      id: initiative.id,
      initiative,
      children: [],
    };

    node.children = simpleTreeBuilder(initiatives, initiative.domainId.itemId);

    result.push(node);
  });

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_AND_LINKS = gql`
  query ManageCompanyInitiatives_query($tenantId: ID) {
    tenantInitiatives2(tenantId: $tenantId) {
      initiatives {
        metadata {
          completedAt
          archived
          status
          supportsInitiatives {
            id
            domainId {
              itemId
              tenantId
            }
          }
          supportsMigs {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
        ...companyInitiativeTable__InitiativeLight
      }
    }
    listTenantLinks(tenantId: $tenantId, type: INITIATIVE) {
      links {
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;
