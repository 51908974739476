import { Typography } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useCountdown } from '../../../../../hooks/useCountdown';
import { friendlyDate } from '../../../../../services/dateFormats';
import { momentToDayjs } from '../../../../../services/dateHelpers';
import './SprintDetails.less';

interface Props {
  sprint: { startDate: Moment | string; endDate: Moment | string };
}

export const SprintDetails = ({ sprint }: Props) => {
  const { t } = useTranslation();
  const { timeDiff, deadlineMissed } = useCountdown(sprint?.endDate);
  if (!sprint) return null;

  var weekDiff = moment(sprint?.endDate)
    .add(1, 'day')
    .diff(sprint?.startDate, 'week');

  return (
    <div className="flx flx--column">
      <div className="SprintDetailsCard__infoContainer">
        <Typography.Text className="SprintDetailsCard__label">
          {t('common.startDate')}
        </Typography.Text>
        <Typography.Text className="SprintDetailsCard__info" type="secondary">
          {friendlyDate(momentToDayjs(sprint.startDate))}
        </Typography.Text>
      </div>
      <div className="SprintDetailsCard__infoContainer">
        <Typography.Text className="SprintDetailsCard__label">
          {t('common.endDate')}
        </Typography.Text>
        <Typography.Text className="SprintDetailsCard__info" type="secondary">
          {friendlyDate(momentToDayjs(sprint.endDate))}
        </Typography.Text>
      </div>
      <div className="SprintDetailsCard__infoContainer">
        <Typography.Text className="SprintDetailsCard__label">
          {t('SprintDetails.SprintDuration')}
        </Typography.Text>
        <Typography.Text type="secondary" className="SprintDetailsCard__info">
          {t('SprintDetails.SprintDurationValue', {
            duration: weekDiff,
            count: weekDiff,
          })}
        </Typography.Text>
      </div>
      <div className="SprintDetailsCard__infoContainer">
        {deadlineMissed && (
          <>
            <Typography.Text className="SprintDetailsCard__label">
              {t('SprintDetails.Overdue')}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              className="SprintDetailsCard__info"
            >
              {t('SprintDetails.TimeOverdue', { time: timeDiff })}
            </Typography.Text>
          </>
        )}
        {!deadlineMissed && (
          <>
            <Typography.Text className="SprintDetailsCard__label">
              {t('SprintDetails.TimeLeft')}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              className="SprintDetailsCard__info"
            >
              {`${timeDiff}`}
            </Typography.Text>
          </>
        )}
      </div>
    </div>
  );
};
