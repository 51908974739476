import {
  BaseInitiative,
  InitiativeNode,
  initiativeTreeBuilder,
} from '../../../../appPages/company/initiatives/InitiativesNavigation/initiativeTreeBuilder';
import { MilestoneStatus } from '../../../../generated/graphql';

type MilestoneInfoWithStats = {
  milestone: Milestone;
  stats: ActivityAndMilestoneStats;
  initiativeDetails: {
    id: string;
    name: string;
    domainId: {
      itemId: string;
      tenantId: string;
    };
  };
};

type Milestone = {
  id: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
  name: string;
  deadlineAt: string;
  metadata: {
    completedAt: string | null;
    status: MilestoneStatus;
  };
};

type ActivityAndMilestoneStats = {
  completed: number;
  overdue: number;
  upcoming: number;
};

type AccumulatedMilestonesWithStats = {
  milestones: MilestoneInfoWithStats[];
  stats: {
    completed: number;
    overdue: number;
    upcoming: number;
  };
};

export interface InitiativeDetailsData<T, U> {
  gantishInitiative: T | U;
  milestones: MilestoneInfoWithStats[];
  accumulatedMilestones: AccumulatedMilestonesWithStats;
  stats: {
    milestones: ActivityAndMilestoneStats;
    activities: ActivityAndMilestoneStats;
  };
}

export type InitiativeDetailsNode<T, U> = InitiativeNode<
  InitiativeDetailsData<T, U>
>;

type InitiativeReportInput<T, U> = {
  allSubInitiatives: {
    id: string;
    initiative: U;
    milestones: { milestone: Milestone; stats: ActivityAndMilestoneStats }[];
    stats: {
      milestones: ActivityAndMilestoneStats;
      activities: ActivityAndMilestoneStats;
    };
  }[];
  stats: {
    milestones: ActivityAndMilestoneStats;
    activities: ActivityAndMilestoneStats;
  };
  initiative: T;
  milestones: { milestone: Milestone; stats: ActivityAndMilestoneStats }[];
};

export function initiativeDetailsTreeBuilder<
  T extends BaseInitiative,
  U extends BaseInitiative,
>(
  initiativeReport: InitiativeReportInput<T, U>
): InitiativeNode<InitiativeDetailsData<T, U>> {
  const subInitiativeNodes: InitiativeNode<InitiativeDetailsData<T, U>>[] =
    initiativeReport.allSubInitiatives.map((si) => ({
      id: si.id,
      key: si.id,
      title: si.initiative.name,
      parentIsCompletedOrArchived: false,
      baseInitiative: si.initiative,
      data: {
        gantishInitiative: si.initiative,
        accumulatedMilestones: {
          milestones: [],
          stats: {
            completed: 0,
            overdue: 0,
            upcoming: 0,
          },
        },
        stats: si.stats,
        milestones: si.milestones.map((m) => ({
          ...m,
          initiativeDetails: {
            id: si.initiative.id,
            name: si.initiative.name,
            domainId: si.initiative.domainId,
          },
        })),
      },
    }));

  const gantishTopNode: InitiativeNode<InitiativeDetailsData<T, U>> = {
    id: initiativeReport.initiative.domainId.itemId,
    key: initiativeReport.initiative.domainId.itemId,
    title: initiativeReport.initiative.name,
    parentIsCompletedOrArchived: false,
    baseInitiative: initiativeReport.initiative,
    data: {
      gantishInitiative: initiativeReport.initiative,
      milestones: initiativeReport.milestones.map((m) => ({
        ...m,
        initiativeDetails: {
          id: initiativeReport.initiative.id,
          name: initiativeReport.initiative.name,
          domainId: initiativeReport.initiative.domainId,
        },
      })),
      accumulatedMilestones: {
        milestones: [],
        stats: {
          completed: 0,
          overdue: 0,
          upcoming: 0,
        },
      },
      stats: initiativeReport.stats,
    },

    children: initiativeTreeBuilder<InitiativeDetailsData<T, U>>(
      subInitiativeNodes,
      initiativeReport.initiative.id,
      initiativeReport.initiative.domainId.itemId,
      initiativeReport.initiative.metadata.archived
    ),
  };

  // Function to recursively accumulate milestones and stats
  function accumulateMilestones(
    node: InitiativeNode<InitiativeDetailsData<T, U>>
  ): AccumulatedMilestonesWithStats {
    let accumulatedMilestones: MilestoneInfoWithStats[] = [
      ...node.data.milestones,
    ];
    let accumulatedStats = {
      completed: 0,
      overdue: 0,
      upcoming: 0,
    };

    if (node.children) {
      node.children.forEach((child) => {
        const childAccumulated = accumulateMilestones(child);
        accumulatedMilestones = accumulatedMilestones.concat(
          childAccumulated.milestones
        );
        accumulatedStats.completed += childAccumulated.stats.completed;
        accumulatedStats.overdue += childAccumulated.stats.overdue;
        accumulatedStats.upcoming += childAccumulated.stats.upcoming;
      });
    }

    // Accumulate current node stats
    accumulatedStats.completed += node.data.stats.milestones.completed;
    accumulatedStats.overdue += node.data.stats.milestones.overdue;
    accumulatedStats.upcoming += node.data.stats.milestones.upcoming;

    node.data.accumulatedMilestones = {
      milestones: accumulatedMilestones,
      stats: accumulatedStats,
    };

    return node.data.accumulatedMilestones;
  }
  // Accumulate milestones and stats for the top node and its children
  accumulateMilestones(gantishTopNode);

  return gantishTopNode;
}
