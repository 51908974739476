import { ApolloError } from '@apollo/client';
import { Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from './ErrorAlert';
import { NumberInput } from './NumberInput';
import { Btn } from './Button';

interface Props {
  form: FormInstance;
  onSubmit: (values: { quantity: string; description: string }) => void;
  akpi: { unit: string; achievementDescriptionMandatory: boolean };
  error?: ApolloError;
  loading: boolean;
  initialValues?: { quantity: number; description?: string | null };
}

const ACHIEVEMENT_DESCRIPTION_MAX_LENGTH = 1000;

export const WeeklyActivityAchievementForm = ({
  form,
  initialValues,
  akpi,
  onSubmit,
  error,
  loading,
}: Props) => {
  const { t } = useTranslation();

  const isEdit = initialValues != null;

  return (
    <Form
      form={form}
      layout="vertical"
      className="mt--xs"
      hideRequiredMark
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <Form.Item
        name="quantity"
        validateTrigger="onBlur"
        className="mb--s"
        label={<div>{t('ReportCard.quantity.label')}</div>}
        rules={[
          {
            required: true,
            message: t('ReportCard.quantity.unitValidationMessage', {
              unit: akpi.unit,
            }),
          },
          {
            validator: (_, value) => {
              if (value < 0) {
                return Promise.reject(t('ReportCard.rules.noNegativeProgress'));
              }

              return Promise.resolve();
            },
          },
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              const regex = /^\d{1,10}([.,]\d*)?$/;

              if (!regex.test(value.toString())) {
                return Promise.reject(t('ReportCard.rules.toLongValue'));
              }

              return Promise.resolve();
            },
          },
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              const regex = /^\d*([.,]\d{1,6})?$/;

              if (!regex.test(value.toString())) {
                return Promise.reject(t('ReportCard.rules.toManydecimals'));
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <NumberInput
          className="fullWidth"
          placeholder={t('ReportCard.quantity.placeholder', {
            unit: akpi.unit,
          })}
        />
      </Form.Item>
      <Form.Item
        name="description"
        label={t('ReportCard.achievementDescription.label')}
        rules={[
          {
            required: akpi.achievementDescriptionMandatory,
            message: t('ReportCard.achievementDescription.mandatory'),
          },
          {
            max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
            message: t('ReportCard.achievementDescription.maxLength', {
              max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
            }),
          },
        ]}
      >
        <Input.TextArea
          autoComplete="off"
          rows={5}
          showCount
          maxLength={ACHIEVEMENT_DESCRIPTION_MAX_LENGTH}
          placeholder={t('ReportCard.achievementDescription.placeholder')}
        />
      </Form.Item>
      <Form.Item>
        <ErrorAlert error={error} title={t('ReportCard.saveError')} />
        <Btn
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          data-intercom-target={
            isEdit
              ? 'Edit Weekly Key Activity Save Button'
              : 'Report Weekly Key Activity Submit Button'
          }
        >
          {isEdit
            ? t('WeeklyActivityAchievementForm.save')
            : t('ReportCard.saveButton')}
        </Btn>
      </Form.Item>
    </Form>
  );
};
