import { useTranslation } from 'react-i18next';
import { Period } from '../../../../../../../../services/GraphTickHelper';
import { NoData } from '../../../../../../../../components/NoData';
import { Graph } from '../../../../Graph';
import { TeamOverviewGraphLegends } from '../../../../TeamOverviewGraphLegends';
import { useKeyActivityPerformance } from '../../../../../../../../hooks/useKeyActivityPerformanceQuery';
import { gql } from '@apollo/client';
import { WkaGraph_WeeklyKeyActivityFragment } from '../../../../../../../../generated/graphql';

interface Props {
  keyActivity: WkaGraph_WeeklyKeyActivityFragment;
  periodData: Period[];
  actions?: React.ReactNode;
}

export const WkaGraph = ({ keyActivity, periodData, actions }: Props) => {
  const { t } = useTranslation();

  const { data: akpiTargetFulfillmentForTeamData } = useKeyActivityPerformance(
    keyActivity.teamId,
    keyActivity.id
  );

  const targetDate = akpiTargetFulfillmentForTeamData?.targetDate;

  const historic = periodData.find(
    (p) => p.targetDate === targetDate
  )?.historic;

  if (periodData.length === 0) {
    return (
      <div className="MigGraph__noData">
        <NoData />
      </div>
    );
  }

  return (
    <div className="MigGraph flx flx--column">
      <div className="flx flx--ai-center flx--jc-space-between">
        <h3 className="mb flx--1">{t('MigGraph.progressOverTime')}</h3>
        {actions}
      </div>

      <Graph
        periodData={periodData}
        startDate={keyActivity.startDate}
        referenceLine={
          akpiTargetFulfillmentForTeamData?.targetDate ?? undefined
        }
        data-intercom-target="Graph for WKA"
      >
        <TeamOverviewGraphLegends
          performance={akpiTargetFulfillmentForTeamData?.targetFulfillment}
          goal={akpiTargetFulfillmentForTeamData?.targetValue}
          progress={akpiTargetFulfillmentForTeamData?.actualValue}
          historic={historic}
        />
      </Graph>
    </div>
  );
};

export const WKA_GRAPH_FRAGMENT = gql`
  fragment WkaGraph_WeeklyKeyActivity on WeeklyKeyActivity {
    id
    startDate
    teamId
    periodData {
      targetDate
      goal
      actual
      historic
    }
  }
`;
