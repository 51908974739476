import { Alert, Form, Tabs, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useLazyTeamTimeline } from '../../../../hooks/useTeamTimeline';
import './WeeklyKeyActivity.less';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { WeeklyKeyActivityBasicForm } from './WeeklyKeyActivity/basicForm/WeeklyKeyActivityBasicForm';
import { KeyActivityLineEditor } from './WeeklyKeyActivity/curveEditor/KeyActivityLineEditor';
import { useTranslation } from 'react-i18next';

import { PeriodDataGraph } from '../../../PeriodDataGraph';
import moment from 'moment';
import { HowweError } from '../../../../services/error-parser';
import { OmitCommitter } from './WeeklyKeyActivity/OmitCommitter';
import {
  MigRelationSelector,
  SupportMig,
} from '../../../../appPages/team/setup/components/MigRelationSelector';
import {
  mergePeriodData,
  timelineToEmptyPeriodData,
  toCumulativePeriods,
} from '../../../../services/akpiUtils';
import dayjs, { Dayjs } from 'dayjs';
import { standardDateFormat } from '../../../../services/dateFormats';
import { isDefined } from '../../../../services/isDefined';
import { Btn } from '../../../Button';

interface Period {
  targetDate: string;
  timePeriodIndex: number;
  label: string;
  goal?: number;
  actual?: number;
}

export interface KeyActivityData {
  id?: string;
  name: string;
  acceptanceCriteria?: string | null;
  currentPeriodIndex?: number | null;
  achievementDescriptionMandatory: boolean;
  commitmentStrategyEnabled: boolean;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  unit: string;
  periodData?: Period[];
  supportsMigsDetails: SupportMig[] | null;
  ignoreListedUsers: string[];
}

export type KeyActivityMetaData = Pick<
  KeyActivityData,
  | 'name'
  | 'acceptanceCriteria'
  | 'achievementDescriptionMandatory'
  | 'commitmentStrategyEnabled'
  | 'description'
  | 'unit'
> & { startDate?: Dayjs | null; endDate?: Dayjs | null };

const initialKeyActivityData: KeyActivityMetaData = {
  name: '',
  acceptanceCriteria: '',
  achievementDescriptionMandatory: false,
  commitmentStrategyEnabled: true,
  description: '',
  unit: '',
};

interface PeriodDataError {
  message: string;
  field: string;
}

interface Props {
  teamId: string;
  tenantId?: string;
  weeklyKeyActivityData?: KeyActivityData;
  handleSave: (data: KeyActivityData) => void;
  saveErrors?: HowweError[];
  saveLoading?: boolean;
  onClose: () => void;
  startDateDisabled: boolean;
}
const BASIC_INFO_TAB = 'BASIC_INFO';
const CURVE_EDITOR_TAB = 'CURVE_EDITOR';
const COMMITMENT_SETTINGS_TAB = 'COMMITMENT_SETTINGS';
const MIG_RELATION = 'MIG_RELATION';

export const WeeklyKeyActivity = ({
  weeklyKeyActivityData,
  handleSave,
  saveErrors,
  saveLoading,
  startDateDisabled,
  teamId,
  tenantId,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [activeDot, setActiveDot] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState(BASIC_INFO_TAB);
  const [periodDataErrors, setPeriodDataErrors] = useState<PeriodDataError[]>(
    []
  );
  const [weeklyKeyActivity, setWeeklyKeyActivity] =
    useState<KeyActivityMetaData>(
      weeklyKeyActivityData
        ? getEditableFields(weeklyKeyActivityData)
        : initialKeyActivityData
    );

  const [periodData, setPeriodData] = useState(
    weeklyKeyActivityData?.periodData ?? []
  );

  const [supportsMigs, setSupportsMigs] = useState<SupportMig[]>(
    () => weeklyKeyActivityData?.supportsMigsDetails ?? []
  );

  const [ignoreListedUsers, setIgnoreListedUsers] = useState(
    weeklyKeyActivityData?.ignoreListedUsers ?? []
  );

  const { getTimeline, timeline } = useLazyTeamTimeline();
  const { startDate, endDate } = weeklyKeyActivity;

  useEffect(() => {
    if (startDate && endDate) getTimeline(teamId, startDate, endDate, tenantId);
  }, [teamId, startDate, endDate, getTimeline, tenantId]);

  const newPeriodData = mergePeriodData(
    periodData ?? [],
    timelineToEmptyPeriodData(timeline)
  );

  const handleSaveForm = async () => {
    try {
      await form.validateFields();
    } catch (e: any) {
      setActiveTab(BASIC_INFO_TAB);
      form.scrollToField(e?.errorFields[0].name);
      return;
    }

    let periodDataErrorMessages = newPeriodData.reduce(
      (acc, p) => {
        const regex = /^\d{1,10}(\.\d{1,6})?$/;
        if (p.goal == null) {
          return acc.concat({
            message: t('WeeklyKeyActivity.emptyGoals'),
            field: p.targetDate,
          });
        }
        if (!regex.test(p.goal.toString())) {
          return acc.concat({
            message: t('WeeklyKeyActivity.goalsInfo'),
            field: p.targetDate,
          });
        }
        return acc;
      },
      [] as { message: string; field: string }[]
    );

    if (periodDataErrorMessages.length > 0) {
      setPeriodDataErrors(periodDataErrorMessages);
      setActiveTab(CURVE_EDITOR_TAB);
    } else {
      handleSave({
        ...weeklyKeyActivity,
        startDate:
          weeklyKeyActivity.startDate &&
          standardDateFormat(weeklyKeyActivity.startDate),
        endDate:
          weeklyKeyActivity.endDate &&
          standardDateFormat(weeklyKeyActivity.endDate),
        periodData: newPeriodData,
        ignoreListedUsers: ignoreListedUsers,
        supportsMigsDetails: supportsMigs,
      });
    }
  };

  return (
    <div className="WeeklyKeyActivity">
      <div className="flx flx--column WeeklyKeyActivity__inputArea">
        <div className="pa--l">
          <Tabs activeKey={activeTab} onTabClick={setActiveTab}>
            <Tabs.TabPane
              key={BASIC_INFO_TAB}
              tab={
                <span data-intercom-target="Key Activity Basic Form Tab">
                  {t('WeeklyKeyActivity.basicInfoTab')}
                </span>
              }
            />
            <Tabs.TabPane
              key={CURVE_EDITOR_TAB}
              tab={
                <span data-intercom-target="Key Activity Goal Adjustment Tab">
                  {t('WeeklyKeyActivity.goalLineTab')}
                </span>
              }
            />
            <Tabs.TabPane
              key={MIG_RELATION}
              tab={
                <span data-intercom-target="MIG Setup Relation Tab">
                  {t('MigSetupModalContent.migRelation')}
                </span>
              }
            ></Tabs.TabPane>
            <Tabs.TabPane
              key={COMMITMENT_SETTINGS_TAB}
              tab={
                <span data-intercom-target="Key Activity Commitment Settings">
                  {t('WeeklyKeyActivity.commitmentSettingsTab')}
                </span>
              }
            />
          </Tabs>
        </div>
        <div className="pa--l WeeklyKeyActivity__scroll">
          <div>
            <ErrorBoundary>
              <WeeklyKeyActivityBasicForm
                teamId={teamId}
                tenantId={tenantId}
                formRef={form}
                weeklyKeyActivity={weeklyKeyActivity}
                setWeeklyKeyActivity={setWeeklyKeyActivity}
                startDateDisabled={startDateDisabled}
                visible={activeTab === BASIC_INFO_TAB}
              />
            </ErrorBoundary>

            {activeTab === CURVE_EDITOR_TAB && (
              <KeyActivityLineEditor
                periodData={newPeriodData}
                setPeriodData={setPeriodData}
                setActiveDot={setActiveDot}
              />
            )}
            {activeTab === COMMITMENT_SETTINGS_TAB && (
              <OmitCommitter
                teamId={teamId}
                value={ignoreListedUsers}
                onChange={setIgnoreListedUsers}
              />
            )}
            {activeTab === MIG_RELATION && (
              <MigRelationSelector
                teamId={teamId}
                tenantId={tenantId}
                value={supportsMigs}
                onChange={setSupportsMigs}
              />
            )}
          </div>
          {saveErrors && (
            <Alert
              className="mt"
              message={t('WeeklyKeyActivity.saveError')}
              description={
                <ul>
                  {saveErrors.map((e, i) => (
                    <li key={i}>{e.translation}</li>
                  ))}
                </ul>
              }
              type="error"
            />
          )}
          {periodDataErrors.length > 0 && (
            <Alert
              className="mt"
              message={t('WeeklyKeyActivity.saveError')}
              description={
                <ul>
                  {periodDataErrors.map((e) => (
                    <li key={e.field + '-' + e.message}>
                      {e.field + ' ' + e.message}
                    </li>
                  ))}
                </ul>
              }
              type="error"
            />
          )}
        </div>
        <div className="flx flx--jc-flx-end pa">
          <Btn type="primary" onClick={handleSaveForm} loading={saveLoading}>
            {t('WeeklyKeyActivity.saveButton')}
          </Btn>
        </div>
      </div>

      <div className="WeeklyKeyActivity__previewArea">
        <div className="pa--xl">
          <Typography.Title level={3}>
            {t('WeeklyKeyActivity.preview')}
          </Typography.Title>

          <PeriodDataGraph
            startDate={(startDate && standardDateFormat(startDate)) ?? moment()}
            periodData={toCumulativePeriods(newPeriodData ?? [])}
            activeDot={activeDot}
            unit={weeklyKeyActivityData?.unit}
            height={200}
          />
        </div>
      </div>
    </div>
  );
};

const getEditableFields = ({
  name,
  acceptanceCriteria,
  achievementDescriptionMandatory,
  commitmentStrategyEnabled,
  startDate,
  endDate,
  description,
  unit,
}: KeyActivityData): KeyActivityMetaData => ({
  name,
  acceptanceCriteria,
  achievementDescriptionMandatory,
  commitmentStrategyEnabled,
  startDate: isDefined(startDate) ? dayjs(startDate) : undefined,
  endDate: isDefined(endDate) ? dayjs(endDate) : undefined,
  description,
  unit,
});
