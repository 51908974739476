import { DatePicker, Divider, Input, Switch, Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleWithTooltip } from '../../../../appPages/team/setup/components/TitleWithTooltip';
import {
  MigInterval,
  MigPreferredOutcome,
} from '../../../../generated/graphql';
import { NumberInput } from '../../../NumberInput';
import { momentToDayjs } from '../../../../services/dateHelpers';
import { Dayjs } from 'dayjs';
import { MigEditableData } from '../MigSetupModalContent';

const { MonthPicker, WeekPicker } = DatePicker;

export interface Props {
  onChange: (formData: any, fixedYScale: boolean) => void;
  formRef: FormInstance;
  migData: MigEditableData;
  endDateHasPassed: boolean;
  fixedYScale: boolean;
  setFixedYScale: (fixed: boolean) => void;
  isEdit: boolean;
  visible?: boolean;
}

export const BasicMigSetupForm = ({
  migData: _migData,
  formRef,
  onChange,
  endDateHasPassed,
  fixedYScale,
  setFixedYScale,
  isEdit,
  visible = true,
}: Props) => {
  const { t } = useTranslation();
  const [selectedInterval, setSelectedInterval] = useState(
    _migData.timePeriodInterval
  );

  const migData = {
    ..._migData,
    startDate: _migData.startDate && momentToDayjs(_migData.startDate),
    firstOutcomeDate:
      _migData.firstOutcomeDate && momentToDayjs(_migData.firstOutcomeDate),
    lastOutcomeDate:
      _migData.lastOutcomeDate && momentToDayjs(_migData.lastOutcomeDate),
  };

  // TODO: Find better solution than display: none. A very weird bug with form refs, can remove this sub component from the DOM, form ref looses its reference
  return (
    <div style={{ minHeight: '100%', display: visible ? 'block' : 'none' }}>
      <div>
        <Form
          layout="vertical"
          form={formRef}
          colon={false}
          hideRequiredMark
          initialValues={migData}
          onValuesChange={(_, all) => onChange(all, fixedYScale)}
        >
          <Form.Item
            name="name"
            label={
              <TitleWithTooltip
                title={t('common.title')}
                tooltipContent={
                  <div className="flx flx--column">
                    {t('BasicMigSetupForm.tooltips.title')}
                  </div>
                }
                tooltipPlacement="topRight"
              />
            }
            rules={[
              { required: true, message: t('BasicMigSetupForm.requiredName') },
              { max: 128, message: t('BasicMigSetupForm.nameLength') },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="description"
            label={
              <TitleWithTooltip
                title={t('common.description')}
                tooltipContent={
                  <div className="flx flx--column">
                    {t('BasicMigSetupForm.tooltips.description')}
                  </div>
                }
                tooltipPlacement="topRight"
              />
            }
            rules={[
              { max: 1024, message: t('BasicMigSetupForm.descriptionLength') },
            ]}
          >
            <Input.TextArea rows={4} showCount maxLength={1024} />
          </Form.Item>
          <Divider style={{ marginTop: 0, marginBottom: 8 }} />
          <Form.Item
            name="startDate"
            label={
              <TitleWithTooltip
                title={t('BasicMigSetupForm.goLiveDate')}
                tooltipContent={
                  <div className="flx flx--column">
                    {t('BasicMigSetupForm.tooltips.activationDate')}
                  </div>
                }
                tooltipPlacement="topRight"
              />
            }
            rules={[
              {
                required: true,
                message: t('BasicMigSetupForm.requiredStartDate'),
              },
            ]}
          >
            <DatePicker disabled={endDateHasPassed} />
          </Form.Item>
          <div className="flx flx--jc-space-between">
            <Form.Item
              name="timePeriodInterval"
              label={
                <TitleWithTooltip
                  title={t('BasicMigSetupForm.interval')}
                  tooltipContent={
                    <div className="flx flx--column">
                      {t('BasicMigSetupForm.tooltips.interval')}
                    </div>
                  }
                  tooltipPlacement="topRight"
                />
              }
            >
              <Select
                style={{ maxWidth: '200px' }}
                disabled={isEdit}
                onChange={setSelectedInterval}
              >
                <Select.Option value={MigInterval.WEEK}>
                  {t('BasicMigSetupForm.week')}
                </Select.Option>
                <Select.Option value={MigInterval.MONTH}>
                  {t('BasicMigSetupForm.month')}
                </Select.Option>
                <Select.Option value={MigInterval.QUARTER}>
                  {t('BasicMigSetupForm.quarter')}
                </Select.Option>
              </Select>
            </Form.Item>
            <FormItemDatePicker
              interval={selectedInterval}
              formRef={formRef}
              initialInterval={migData.timePeriodInterval}
              endDateHasPassed={endDateHasPassed}
            />
          </div>

          <Divider style={{ marginTop: 0, marginBottom: 8 }} />
          <div className="flx">
            <Form.Item
              name="preferredOutcome"
              label={t('BasicMigSetupForm.preferredOutcome')}
              className="mr"
              rules={[{ required: true }]}
            >
              <Select>
                <Select.Option value={MigPreferredOutcome.HIGH}>
                  {t('BasicMigSetupForm.highIsPreferred')}
                </Select.Option>
                <Select.Option value={MigPreferredOutcome.LOW}>
                  {t('BasicMigSetupForm.lowIsPreferred')}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="unit"
              label={t('BasicMigSetupForm.unit')}
              rules={[
                {
                  required: true,
                  message: t('BasicMigSetupForm.unitRequired'),
                },
                {
                  min: 1,
                  max: 64,
                  message: t('BasicMigSetupForm.unitErrorCharacters'),
                },
              ]}
            >
              <Input autoComplete="off" placeholder="E.g. MSEK" />
            </Form.Item>
          </div>
          <Form.Item
            label={
              <TitleWithTooltip
                title={t('BasicMigSetupForm.fixedScale')}
                tooltipContent={
                  <div className="flx flx--column">
                    {t('BasicMigSetupForm.tooltips.fixedScale')}
                  </div>
                }
                tooltipPlacement="topRight"
              />
            }
          >
            <Switch
              checked={fixedYScale}
              onChange={(checked) => setFixedYScale(checked)}
            />
          </Form.Item>
          {fixedYScale && (
            <div className="flx ">
              <Form.Item name="minY" label="Y-min" className="mr">
                <NumberInput autoComplete="off" placeholder="Auto" />
              </Form.Item>
              <Form.Item name="maxY" label="Y-max">
                <NumberInput autoComplete="off" placeholder="Auto" />
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

interface formItemDatePickerProps {
  interval: MigInterval;
  formRef: FormInstance;
  initialInterval: MigInterval;
  endDateHasPassed: boolean;
}

const FormItemDatePicker = ({
  interval,
  initialInterval,
  formRef,
  endDateHasPassed,
}: formItemDatePickerProps) => {
  const { t } = useTranslation();

  let Picker;
  switch (interval) {
    case MigInterval.WEEK:
      Picker = WeekPicker;
      break;
    case MigInterval.QUARTER:
    case MigInterval.MONTH:
      Picker = MonthPicker;
      break;
    default:
      Picker = MonthPicker;
  }

  const isDisabledFirstOutcomeDate = (current: Dayjs) => {
    const startDate: Dayjs | undefined = formRef.getFieldValue('startDate');
    const lastOutcomeDate: Dayjs | undefined =
      formRef.getFieldValue('lastOutcomeDate');

    const migInterval: MigInterval =
      formRef.getFieldValue('timePeriodInterval') || initialInterval;

    const endOfInterval = current
      .clone()
      .endOf(migInterval.toLocaleLowerCase() as any);

    const isBeforeOrSameAsStartDate =
      !!startDate && endOfInterval.isSameOrBefore(startDate.endOf('day'));
    const isAfterOrSameAsLastOutcomeDate =
      !!lastOutcomeDate &&
      endOfInterval.isSameOrAfter(lastOutcomeDate.endOf('day'));

    return isBeforeOrSameAsStartDate || isAfterOrSameAsLastOutcomeDate;
  };

  const isDatesEvenlyDivisibleByQuarters = (
    pickerDate: Dayjs,
    startDate: Dayjs
  ) => {
    const firstOutcomeDate = startDate.clone().add(2, 'month');
    return (pickerDate.month() - firstOutcomeDate.month()) % 3 !== 0;
  };

  const isDisabledLastOutcomeDate = (pickerDate: Dayjs, isQuarter = false) => {
    const startDate: Dayjs | undefined = formRef.getFieldValue('startDate');
    const firstOutcomeDate: Dayjs | undefined =
      formRef.getFieldValue('firstOutcomeDate');

    const isBeforeOrSameAsStartDate =
      !!startDate && pickerDate.isSameOrBefore(startDate.endOf('day'));

    const isBeforeOrSameAsFirstOutcomeDate =
      !!firstOutcomeDate &&
      pickerDate.isSameOrBefore(firstOutcomeDate.endOf('day'));

    const isNotAvailableToSelectForQuarter = isQuarter
      ? !!firstOutcomeDate &&
        isDatesEvenlyDivisibleByQuarters(pickerDate, firstOutcomeDate)
      : false;

    return (
      isBeforeOrSameAsStartDate ||
      isBeforeOrSameAsFirstOutcomeDate ||
      isNotAvailableToSelectForQuarter
    );
  };

  return (
    <>
      <Form.Item
        name="firstOutcomeDate"
        label={t('common.startDate')}
        className="flx--1 ml"
        rules={[
          {
            required: true,
            message: t('FormItemDatePicker.firstOutcomeDateMandatory'),
          },
        ]}
      >
        <Picker
          style={{ width: '100%' }}
          allowClear={false}
          disabled={endDateHasPassed}
          disabledDate={isDisabledFirstOutcomeDate}
        />
      </Form.Item>
      <Form.Item
        name="lastOutcomeDate"
        label={t('common.endDate')}
        validateTrigger="onBlur"
        className="flx--1 ml"
        rules={[
          {
            required: true,
            message: t('FormItemDatePicker.lastOutcomeDateMandatory'),
          },
          {
            validator(_, value) {
              if (!value) return Promise.resolve();

              const firstOutcomeDate =
                formRef.getFieldValue('firstOutcomeDate');
              if (
                interval === MigInterval.QUARTER &&
                firstOutcomeDate &&
                !isDatesEvenlyDivisibleByQuarters(firstOutcomeDate, value)
              ) {
                return Promise.reject(
                  'Not a valid end-date for the selected interval: Quarter'
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Picker
          style={{ width: '100%' }}
          disabledDate={(date) =>
            isDisabledLastOutcomeDate(date, interval === MigInterval.QUARTER)
          }
          allowClear={false}
        />
      </Form.Item>
    </>
  );
};
