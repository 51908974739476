import { Form, FormInstance, Input, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import {
  Action,
  InitiativeContentFragment,
  TeamResourceType,
} from '../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import { Icons, InitiativeIcon } from '../InitiativeIcons';
import TextArea from 'antd/es/input/TextArea';
import { PermittedTeamSelectorForAction } from '../initiativeDrawer/PermittedTeamSelectorForAction';
import { useMigForInitiativeLazy } from '../hooks/useMigsForInitiative';
import { MigSelector } from '../initiativeDrawer/MigSelector';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { TagColorBox } from '../../../../../components/initiative/ColorPicker';
import { Btn } from '../../../../../components/Button';
import cx from 'classnames';

interface Props {
  loading?: boolean;
  form: FormInstance<any>;
  initialValue?: InitiativeContentFragment;
}

export const SubInitiativeForm = ({ loading, form, initialValue }: Props) => {
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>();
  const tagTitle = Form.useWatch('tagTitle', form) as string;
  const selectedMigIds = Form.useWatch('migs', form) as string[];
  const { t } = useTranslation();

  const initialValueTeamId = initialValue?.domainId.teamId;

  const [fetchMigsForInitiativeLazy, { activeOrUpcomingMigs }] =
    useMigForInitiativeLazy();

  useEffect(() => {
    if (selectedTeamId || initialValueTeamId) {
      fetchMigsForInitiativeLazy({
        variables: { teamId: (selectedTeamId ?? initialValueTeamId)! },
      });
    }
  }, [selectedTeamId, fetchMigsForInitiativeLazy, initialValueTeamId]);

  const formInitialValue = {
    colorCode: initialValue?.tag.colorCode,
    iconId: initialValue?.tag.iconId,
    objectives: [''],
  };

  return (
    <div>
      {loading ? (
        <Skeleton />
      ) : (
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          initialValues={formInitialValue}
        >
          <h4 className="InitiativeForm__subHeaders mb--l">
            {t('InitiativeForm.subInitiativeSetup')}
          </h4>
          {initialValue?.tag && (
            <div className="mb">
              <InitiativeTag
                title={initialValue.tag.title}
                borderColor={initialValue.tag.colorCode}
                icon={Icons[initialValue.tag.iconId as InitiativeIcon]}
                completed={initialValue.completed?.value}
                archived={initialValue.archived}
              />
            </div>
          )}

          <Form.Item
            name="name"
            label={t('common.title')}
            rules={[
              {
                required: true,
                message: t('InitiativeForm.titleError'),
              },
              {
                min: 2,
                max: 51,
                message: t('InitiativeForm.titleError'),
              },
            ]}
          >
            <Input
              type="text"
              placeholder={t('InitiativeForm.titlePlaceholder')}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('common.initiative.purpose')}
            rules={[
              {
                required: true,
                message: t('InitiativeForm.purposeError'),
              },
              {
                min: 2,
                max: 256,
                message: t('InitiativeForm.purposeError'),
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item label={t('common.initiative.objectives')} required={false}>
            <Form.List name="objectives">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map((field, index) => (
                    <div className="flx" key={field.key}>
                      <Form.Item
                        {...field}
                        className={cx('flx--1', {
                          mb: index === fields.length - 1,
                        })}
                      >
                        <Input />
                      </Form.Item>
                      <Btn
                        type="text"
                        icon={
                          <DeleteOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      />
                    </div>
                  ))}
                  <Btn
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      add();
                    }}
                  >
                    {t('InitiativeForm.addObjective')}
                  </Btn>
                </div>
              )}
            </Form.List>
          </Form.Item>

          <h4 className="InitiativeForm__subHeaders mb--l">
            {t('InitiativeForm.initiativeTagSetup')}
          </h4>
          <Form.Item
            name="tagTitle"
            label={t('InitiativeForm.tagTitle')}
            rules={[
              {
                required: true,
                message: t('InitiativeForm.tagError'),
              },
              {
                min: 2,
                max: 20,
                message: t('InitiativeForm.tagError'),
              },
            ]}
          >
            <Input
              type="text"
              placeholder={t('InitiativeForm.tagTitlePlaceholder')}
              maxLength={21}
            />
          </Form.Item>

          <Form.Item label={t('InitiativeForm.tagPreview')}>
            <InitiativeTag
              title={tagTitle}
              borderColor={formInitialValue.colorCode ?? TagColorBox.blue}
              icon={Icons[formInitialValue?.iconId as InitiativeIcon]}
              completed={initialValue?.completed?.value}
              archived={initialValue?.archived}
            />
          </Form.Item>
          <h4 className="InitiativeForm__subHeaders">
            {t('InitiativeForm.alignment')}
          </h4>
          <h5 className="InitiativeForm__subHeaders__description mb--l">
            {t('InitiativeForm.alignmentDescription')}
          </h5>
          <Form.Item
            label={t('InitiativeForm.teamSelect')}
            name="teamId"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('InitiativeForm.teamSelectError'),
              },
            ]}
          >
            <PermittedTeamSelectorForAction
              teamResourceType={TeamResourceType.TEAM_INITIATIVE}
              action={Action.CREATE}
              onChange={(value) => {
                form.setFieldValue('migs', ['']);
                setSelectedTeamId(value);
              }}
            />
          </Form.Item>
          <Form.Item label="Migs" required>
            <Form.List name="migs">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <div
                        className="flx"
                        key={field.key + (selectedTeamId ?? 'no-team-selected')}
                      >
                        <Form.Item
                          className={cx('flx--1', {
                            mb: index === fields.length - 1,
                          })}
                          {...field}
                        >
                          <MigSelector
                            selectedMigIds={selectedMigIds ?? []}
                            migs={activeOrUpcomingMigs}
                            disabled={
                              selectedTeamId === undefined &&
                              initialValueTeamId === undefined
                            }
                          />
                        </Form.Item>
                        {index > 0 ? (
                          <Btn
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              remove(field.name);
                            }}
                          ></Btn>
                        ) : null}
                      </div>
                    ))}
                    <Form.Item>
                      <Btn
                        type="link"
                        disabled={selectedTeamId === undefined}
                        icon={<PlusOutlined />}
                        onClick={() => {
                          add();
                        }}
                      >
                        {t('InitiativeForm.addMigAlignment')}
                      </Btn>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
