import { DatePicker, Divider, Input, Switch, Form, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';

import './WeeklyKeyActivityBasicForm.less';
import { useAccelerationDay } from '../../../../../../hooks/useAccelerationDay';
import { useClosestPeriods } from '../../../../../../hooks/useClosestPeriods';
import { FRIENDLY_DATE_FORMAT } from '../../../../../../services/dateFormats';
import { KeyActivityMetaData } from '../../WeeklyKeyActivity';
import {
  isAccDay,
  isEndOfPeriod,
  isInCurrentPeriod,
} from '../../../../../../services/accelerationDayHelpers';
import { useTranslation } from 'react-i18next';
import { TitleWithTooltip } from '../../../../../../appPages/team/setup/components/TitleWithTooltip';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  teamId: string;
  tenantId?: string;
  formRef: FormInstance;
  setWeeklyKeyActivity: (weeklyKeyActivity: KeyActivityMetaData) => void;
  weeklyKeyActivity?: KeyActivityMetaData;
  startDateDisabled: boolean;
  visible?: boolean;
}

const DEFAULT_PERIOD_LENGTH_IN_DAYS = 7;

export const WeeklyKeyActivityBasicForm = ({
  weeklyKeyActivity,
  setWeeklyKeyActivity,
  formRef,
  startDateDisabled = false,
  visible = true,
  teamId,
  tenantId,
}: Props) => {
  const { t } = useTranslation();

  const { data: accelerationDay } = useAccelerationDay(teamId, tenantId);
  const { currentPeriod } = useClosestPeriods(teamId, tenantId);

  const isDisabledStartDate = (date: Dayjs) => {
    if (!currentPeriod) return true; // Disabled all dates until we know current period

    // // Cannot be in periods before the current one
    if (date.isBefore(currentPeriod.timePeriodStartDate, 'day')) return true;

    // If an acc. day has changed during the currentPeriod we want to prevent
    // selection of the new acc. day in the current period, since the current (now longer) period had its official acc. day on the period startDate
    if (isInCurrentPeriod(date, currentPeriod)) return true;

    const isStartOfCurrentPeriod = date.isSame(
      currentPeriod.timePeriodStartDate,
      'day'
    );

    // must fall on the teams current acceleration day unless it's the start of the current period
    // (in which case it can differ due to to acc. day changes in the current period)
    if (
      !isStartOfCurrentPeriod &&
      accelerationDay &&
      !isAccDay(date, accelerationDay)
    )
      return true;

    // Check if startDate is after or same as the endDate
    const endDate: Dayjs | undefined = formRef.getFieldValue('endDate');

    const isLessThanTwoPeriodsApart =
      endDate != null &&
      endDate.diff(date, 'days') <= DEFAULT_PERIOD_LENGTH_IN_DAYS;

    if (isLessThanTwoPeriodsApart) return true;

    const isAfterOrSameAsLastTargetDate =
      !!endDate && date.isSameOrAfter(endDate.endOf('day'));

    return isAfterOrSameAsLastTargetDate;
  };

  const isDisabledEndDate = (date: Dayjs) => {
    if (!currentPeriod) return true; // Disabled all dates until we know current period

    // Disable all dates that are in the past from the current period endDate
    if (date.isBefore(currentPeriod.timePeriodEndDate, 'day')) return true;

    // Disable all dates that are not valid end-dates
    if (accelerationDay && !isEndOfPeriod(date, accelerationDay)) return true;

    const startDate: Dayjs | undefined = formRef.getFieldValue('startDate');

    const isLessThanTwoPeriodsApart =
      startDate != null &&
      date.diff(startDate, 'days') <= DEFAULT_PERIOD_LENGTH_IN_DAYS;

    if (isLessThanTwoPeriodsApart) return true;

    const isBeforeOrSameAsStartDate =
      !!startDate && date.isSameOrBefore(startDate.endOf('day'));

    return isBeforeOrSameAsStartDate;
  };
  // TOOD: Good enough? Due to weird form ref issue this sub component cant be removed from the DOM, hence only hidden
  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      <div>
        <Form
          layout="vertical"
          form={formRef}
          colon={false}
          hideRequiredMark
          onValuesChange={(changed, all) => setWeeklyKeyActivity(all)}
          initialValues={{
            ...weeklyKeyActivity,
            startDate:
              weeklyKeyActivity?.startDate &&
              dayjs(weeklyKeyActivity.startDate),
            endDate:
              weeklyKeyActivity?.endDate && dayjs(weeklyKeyActivity.endDate),
          }}
        >
          <Form.Item
            name="name"
            label={
              <TitleWithTooltip
                title={t('common.title')}
                tooltipContent={t('WeeklyKeyActivityBasicForm.tooltip.name')}
              />
            }
            rules={[
              {
                required: true,
                message: t('WeeklyKeyActivityBasicForm.nameHelper'),
              },
              {
                max: 128,
                message: t('WeeklyKeyActivityBasicForm.nameLength'),
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="description"
            label={
              <TitleWithTooltip
                title={t('common.description')}
                tooltipContent={t(
                  'WeeklyKeyActivityBasicForm.tooltip.description'
                )}
              />
            }
            rules={[{ max: 500 }]}
          >
            <Input.TextArea rows={4} showCount maxLength={500} />
          </Form.Item>
          <Form.Item
            name="unit"
            label={
              <TitleWithTooltip
                title={t('common.unit')}
                tooltipContent={t('WeeklyKeyActivityBasicForm.tooltip.unit')}
              />
            }
            className="flx--1"
            rules={[
              {
                required: true,
                message: t('WeeklyKeyActivityBasicForm.unitHelper'),
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <div className="flx flx--jc-space-between">
            <Form.Item
              name="startDate"
              label={t('common.startDate')}
              className="flx--1"
              rules={[
                {
                  required: true,
                  message: t('WeeklyKeyActivityBasicForm.startDateHelper'),
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                allowClear={false}
                showToday={false}
                disabled={startDateDisabled}
                disabledDate={isDisabledStartDate}
                format={FRIENDLY_DATE_FORMAT}
              />
            </Form.Item>
            <Form.Item
              name="endDate"
              label={t('common.endDate')}
              className="flx--1 ml"
              rules={[
                {
                  required: true,
                  message: t('WeeklyKeyActivityBasicForm.endDateHelper'),
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={isDisabledEndDate}
                allowClear={false}
                showToday={false}
                format={FRIENDLY_DATE_FORMAT}
              />
            </Form.Item>
          </div>
          <Divider />

          <Typography.Title level={4}>
            {t('WeeklyKeyActivityBasicForm.reportingAchievement')}
          </Typography.Title>

          <Form.Item
            name="acceptanceCriteria"
            label={
              <TitleWithTooltip
                title={t('common.definitionOfDone')}
                tooltipContent={t(
                  'WeeklyKeyActivityBasicForm.tooltip.definitionOfDone'
                )}
              />
            }
            rules={[{ max: 500 }]}
          >
            <Input.TextArea
              rows={4}
              placeholder={t(
                'WeeklyKeyActivityBasicForm.definitionOfDoneExample'
              )}
              showCount
              maxLength={500}
            />
          </Form.Item>
          <div className="flx flx--jc-space-between mb">
            <Form.Item
              name="commitmentStrategyEnabled"
              label={
                <TitleWithTooltip
                  title={t('WeeklyKeyActivityBasicForm.commitmentStrategy')}
                  tooltipContent={t(
                    'WeeklyKeyActivityBasicForm.tooltip.commitmentStrategy'
                  )}
                />
              }
              valuePropName="checked"
            >
              <Switch
                checkedChildren={t('WeeklyKeyActivityBasicForm.enabled')}
                unCheckedChildren={t('WeeklyKeyActivityBasicForm.disabled')}
              />
            </Form.Item>
          </div>
          <div className="flx flx--jc-space-between mb">
            <Form.Item
              name="achievementDescriptionMandatory"
              label={
                <TitleWithTooltip
                  title={t(
                    'WeeklyKeyActivityBasicForm.achievementDescriptionMandatory'
                  )}
                  tooltipContent={t(
                    'WeeklyKeyActivityBasicForm.tooltip.achievementDescriptionMandatory'
                  )}
                />
              }
              valuePropName="checked"
            >
              <Switch
                checkedChildren={t('WeeklyKeyActivityBasicForm.mandatory')}
                unCheckedChildren={t('WeeklyKeyActivityBasicForm.optional')}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};
