import { useTranslation } from 'react-i18next';
import { MigProgressIcon } from '../../../../../icons/newTeamPageIcons/MigProgressIcon';
import { WkaProgressIcon } from '../../../../../icons/newTeamPageIcons/WkaProgressIcon';
import { TeamActivityIcon } from '../../../../../icons/newTeamPageIcons/TeamActivityIcon';
import { SuccessIcon } from '../../../../../icons/performanceIcons/SuccessIcon';
import { roundToFloor } from '../../../../../services/roundNumbersHelper';
import dayjs from 'dayjs';
import './TeamOverviewSection.less';
import { TeamActivityProgressCard } from './TeamActivityProgressCard';
import { OverviewProgressCard } from '../progressCard/OverviewProgressCard';
import { CompletionCard } from '../progressCard/CompletionCard';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetCurrentSprintTermForTeamOverviewSectionDocument,
  GetKeyActivityTargetFulfillment_TeamOverviewSectionDocument,
  GetTeamActivityDocument,
  KeyActivityType,
} from '../../../../../generated/graphql';
import { statusColorHelper } from '../../../../../services/statusColorHelper';
import { Colors } from '../../../../componentLibrary/Colors';
import { match } from 'ts-pattern';
import { TeamOverviewSectionSkeleton } from './TeamOverviewSection.skeleton';
import { getWkaAveragePerf } from './utils/getWkaAveragePerf';

interface Props {
  teamId: string;
  migTotalPerformance?: number | null;
  totalMigsThatNeedsToBeUpdated?: number;
}

export const TeamOverviewSection = ({
  teamId,
  migTotalPerformance,
  totalMigsThatNeedsToBeUpdated,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(
    GetCurrentSprintTermForTeamOverviewSectionDocument,
    { variables: { teamId } }
  );
  const { data: teamActivity } = useSuspenseQuery(GetTeamActivityDocument, {
    variables: { teamId },
  });

  const { data: kaTargetFulfillmentInfo } = useSuspenseQuery(
    GetKeyActivityTargetFulfillment_TeamOverviewSectionDocument,
    { variables: { teamId } }
  );

  const kaPerf = kaTargetFulfillmentInfo.keyActivityTargetFulfillmentForTeam;
  const wkaAveragePerf = getWkaAveragePerf(kaPerf?.details);

  const sprintKaInformation = kaPerf?.details?.find(
    (d) => d.type === KeyActivityType.TERM
  );

  let daysSinceLastMeeting: number | undefined;

  const teamMeetingOverview = teamActivity?.accelerationMeetingOverviewTeamV2;

  if (teamMeetingOverview?.neverStarted === false) {
    const mostRecentMeetingDate = teamMeetingOverview.mostRecentMeetingDate;

    const lastAccMeeting =
      mostRecentMeetingDate && dayjs(mostRecentMeetingDate);
    const todaysDate = dayjs();
    daysSinceLastMeeting = todaysDate?.diff(lastAccMeeting, 'day');
  }

  const totalMembers = teamMeetingOverview?.totalMembers;
  const totalActiveMembers = teamMeetingOverview?.activeMembers;

  let teamActivityColor: string = '';

  match(teamMeetingOverview)
    .with({ active: true }, () => (teamActivityColor = Colors.Status.OK_GREEN))
    .with(
      { active: false, neverStarted: false },
      () => (teamActivityColor = Colors.Status.ERROR_RED)
    )
    .with(
      { neverStarted: true },
      () => (teamActivityColor = Colors.Grays.DISABLED_FONTS)
    )
    .otherwise(() => (teamActivityColor = Colors.Grays.DISABLED_FONTS));

  return (
    <div>
      <h2 className="mb--l">{t('TeamOverviewSection.header')}</h2>
      <div
        className="TeamOverviewSection__container"
        data-intercom-target="Team overview header"
      >
        <OverviewProgressCard
          SvgIcon={MigProgressIcon}
          title={t('TeamOverviewSection.migProgress')}
          value={roundToFloor(migTotalPerformance)}
          isPercentage={true}
          subText={
            totalMigsThatNeedsToBeUpdated !== 0 ? (
              <div className="TeamOverviewSection__updateMig flx flx--jc-space-between txt--secondary">
                {t('TeamOverviewSection.updateMigs')}
                <span className="TeamOverviewSection__updateMigValue">
                  {totalMigsThatNeedsToBeUpdated}
                </span>
              </div>
            ) : undefined
          }
          data-intercom-target="Average MIG progress card"
        />
        {wkaAveragePerf != null && (
          <OverviewProgressCard
            SvgIcon={WkaProgressIcon}
            title={t('TeamOverviewSection.wkaProgress')}
            value={roundToFloor(wkaAveragePerf)}
            isPercentage={true}
            data-intercom-target="Average weekly key activity progress card"
          />
        )}
        {data?.currentMitemTerm != null && (
          <CompletionCard
            SvgIcon={SuccessIcon}
            iconColor={statusColorHelper(
              sprintKaInformation?.targetFulfillment
            )}
            title={t('TeamOverviewSection.skaProgress')}
            numberOfCompletedActivities={sprintKaInformation?.actualValue ?? 0}
            numberOfSprintKeyActivities={sprintKaInformation?.targetValue ?? 0}
            valueColor={statusColorHelper(
              sprintKaInformation?.targetFulfillment
            )}
            data-intercom-target="Sprint key activity progress card"
          />
        )}
        <TeamActivityProgressCard
          SvgIcon={TeamActivityIcon}
          iconColor={teamActivityColor}
          daysSinceLastMeeting={daysSinceLastMeeting}
          totalMembers={totalMembers ?? 0}
          totalActiveMembers={totalActiveMembers ?? 0}
          data-intercom-target="Active members and days since last meeting card"
        />
      </div>
    </div>
  );
};

TeamOverviewSection.Skeleton = TeamOverviewSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_CURRENT_SPRINT_TERM_FOR_TEAM_OVERVIEW_SECTION = gql`
  query getCurrentSprintTermForTeamOverviewSection($teamId: ID!) {
    currentMitemTerm(teamId: $teamId) {
      id
      periodData {
        goal
        actual
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_ACTIVITY = gql`
  query getTeamActivity($teamId: ID!) {
    accelerationMeetingOverviewTeamV2(teamId: $teamId) {
      id
      name
      active
      totalMembers
      activeMembers
      neverStarted
      mostRecentMeetingDate
    }
  }
`;

export const GET_TEAM_KEY_ACTIVITY_TARGET_FULFILLMENT = gql`
  query getKeyActivityTargetFulfillment_TeamOverviewSection(
    $teamId: ID!
    $tenantId: ID
  ) {
    keyActivityTargetFulfillmentForTeam(teamId: $teamId, tenantId: $tenantId) {
      teamTargetFulfillment
      details {
        id
        name
        type
        actualValue
        targetDate
        targetFulfillment
        targetValue
      }
    }
  }
`;
