import { useLazyQuery, gql } from '@apollo/client';
import moment from 'moment';
import { useEffect } from 'react';
import { USER_DISPLAY_NAME_FRAGMENT } from '../components/DisplayName';
import {
  AccelerationMeetingFragment,
  AccelerationMeetingsPaginatedDocument,
  Action,
  AkpiAchievementFragment,
  TimePeriodFilter,
} from '../generated/graphql';
import { useTeamPermissions } from '../usePermissions';

interface Props {
  teamId: string;
  weeklyKeyActivityId: string;
  timePeriodFilter?: TimePeriodFilter;
  tenantId?: string;
}

export const useTeamKeyActivityLog = ({
  teamId,
  weeklyKeyActivityId,
  timePeriodFilter,
  tenantId,
}: Props) => {
  const { isAllowed, loading: permissionLoading } = useTeamPermissions({
    requestedAction: {
      resource: 'achievement',
      action: Action.READ,
    },

    teamId: teamId,
  });

  const [load, { data, error, loading }] = useLazyQuery(
    AccelerationMeetingsPaginatedDocument,
    {
      variables: {
        teamId,
        filter: { akpiId: weeklyKeyActivityId },
        timePeriodFilter,
        weeklyKeyActivityId: weeklyKeyActivityId,
        tenantId,
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (isAllowed) {
      load();
    }
  }, [isAllowed, load]);

  const accelerationMeetings = data?.accelerationMeetingsPaginated?.content;
  const keyActivityUnit = data?.weeklyKeyActivity.unit;
  const newMeetings =
    accelerationMeetings?.map((am) =>
      accelerationMeetingTransform(am, keyActivityUnit)
    ) ?? [];

  const achievements =
    accelerationMeetings?.flatMap(
      (m) =>
        m?.accelerationKpiAchievements?.flatMap((akpiAchievements) =>
          akpiAchievements.achievements.map((aa) =>
            achievementTransform(aa, keyActivityUnit)
          )
        ) ?? []
    ) ?? [];

  // TODO: Future, make sure to filter achievements data in graphQL instead of here, in that
  // way, we don't need the filter
  const sortedData: (AccelerationMeetingEntry | AkpiAchievementEntry)[] = [
    ...newMeetings,
    ...achievements,
  ]
    .filter((a) => moment(a.sortDate).isBefore(data?.weeklyKeyActivity.endDate))
    .sort((a, b) => b.sortDate.localeCompare(a.sortDate));

  return {
    data: sortedData,
    authorized: error ? error.message === 'unauthorized' : isAllowed,
    error,
    loading: permissionLoading || loading,
  };
};

const accelerationMeetingTransform = (
  m: AccelerationMeetingFragment,
  keyActivityUnit?: string | null
) => {
  return { sortDate: m.periodStartDate!, unit: keyActivityUnit, ...m };
};

const achievementTransform = (
  accelerationKpiAchievements: AkpiAchievementFragment,
  keyActivityUnit?: string | null
) => {
  return {
    sortDate: accelerationKpiAchievements.created,
    unit: keyActivityUnit,
    ...accelerationKpiAchievements,
  };
};

export type AccelerationMeetingEntry = ReturnType<
  typeof accelerationMeetingTransform
>;

export type AkpiAchievementEntry = ReturnType<typeof achievementTransform>;

const AKPI_ACHIEVEMENT_FRAGMENT = gql`
  fragment AkpiAchievement on AkpiAchievement {
    id
    accelerationKpiId
    quantity
    description
    userId
    created
    user {
      id
      ...DisplayName_User
    }
  }
  ${USER_DISPLAY_NAME_FRAGMENT}
`;

const ACCELERATION_MEETING_FRAGMENT = gql`
  fragment AccelerationMeeting on TeamAccelerationMeeting {
    periodStartDate
    periodEndDate
    created
    teamId
    id
    accelerationKpiCommitments {
      accelerationKpiId
      commitmentsTotal
      commitments {
        quantity
        note
        user {
          id
          email
          name
          displayName
        }
      }
    }
    accelerationKpiAchievements(akpiId: $weeklyKeyActivityId) {
      achievements {
        ...AkpiAchievement
      }
    }
  }
  ${AKPI_ACHIEVEMENT_FRAGMENT}
`;

export const ACCELERATION_MEETINGS_PAGINATED = gql`
  query accelerationMeetingsPaginated(
    $teamId: ID!
    $filter: AccelerationMeetingFilter
    $timePeriodFilter: TimePeriodFilter
    $weeklyKeyActivityId: ID!
    $tenantId: ID
  ) {
    accelerationMeetingsPaginated(
      teamId: $teamId
      filter: $filter
      timePeriodFilter: $timePeriodFilter
      tenantId: $tenantId
    ) {
      content {
        ...AccelerationMeeting
      }
    }
    weeklyKeyActivity(
      teamId: $teamId
      weeklyKeyActivityId: $weeklyKeyActivityId
    ) {
      id
      unit
      startDate
      endDate
    }
  }
  ${ACCELERATION_MEETING_FRAGMENT}
`;
