import { useEffect } from 'react';
import { Btn } from '../../../../components/Button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { AccelerationMeetingStatusDocument } from '../../../../generated/graphql';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

interface Props {
  teamId: string;
}

export const AccMeetingButton = ({ teamId }: Props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();

  const [
    fetchMeetingStatus,
    {
      error: meetingStatusError,
      data: meetingStatusData,
      loading: meetingStatusLoading,
    },
  ] = useLazyQuery(AccelerationMeetingStatusDocument, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    fetchMeetingStatus({ variables: { teamId: teamId } });
  }, [fetchMeetingStatus, teamId]);

  const meetingStatus = meetingStatusData?.accelerationMeetingStatus;

  const disabled =
    !meetingStatus?.hasActivities ||
    !!meetingStatusError ||
    meetingStatusLoading;

  return (
    <div>
      <Tooltip
        title={
          !meetingStatus?.hasActivities &&
          !meetingStatusLoading &&
          t('AccMeetingButton.disabledAccMeetingButton')
        }
      >
        <span>
          <Btn
            type={meetingStatus?.meetingHeld ? 'default' : 'primary'}
            disabled={disabled}
            icon={meetingStatus?.meetingHeld ? <EditOutlined /> : undefined}
            onClick={() =>
              history.push(
                match.url +
                  '/acceleration-meeting' +
                  (meetingStatus?.meetingHeld ? '/edit' : '')
              )
            }
            data-intercom-target="Start Acceleration Meeting/Edit Commitments Button"
          >
            {meetingStatus?.meetingHeld
              ? t('AccMeetingButton.editAccelerationMeetingButton')
              : t('AccMeetingButton.accelerationMeetingButton')}
          </Btn>
        </span>
      </Tooltip>
    </div>
  );
};

export const GET_ACCELERATION_MEETING_STATUS = gql`
  query accelerationMeetingStatus($teamId: ID!) {
    accelerationMeetingStatus(teamId: $teamId) {
      hasActivities
      meetingHeld
    }
  }
`;
