import { useTranslation } from 'react-i18next';
import { TeamSelect } from './teamMigSelector/TeamSelect';
import { Form, Typography } from 'antd';
import { useState } from 'react';
import { TeamMigSelect } from './teamMigSelector/TeamMigSelect';
import { MigStatus } from '../../../generated/graphql';
import { Btn } from '../../Button';

export type TeamMig = {
  id: string;
  name: string;
  domainId: {
    itemId: string;
    teamId: string;
  };
};

interface Props {
  onCancel?: () => void;
  onAddTeamMig: (mig: TeamMig) => void;
}

export const TeamMigSelector = ({ onCancel, onAddTeamMig }: Props) => {
  const { t } = useTranslation();
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
    undefined
  );
  const [teamMig, setTeamMig] = useState<TeamMig | undefined>(undefined);

  return (
    <Form layout="vertical">
      <Typography.Title level={4}>
        {t('TeamMigSelector.title')}
      </Typography.Title>
      <Form.Item
        name="teamSelector"
        className="flx--1"
        label={t('TeamMigSelector.teamSelectorLabel')}
      >
        <TeamSelect
          onChange={(teamId) => setSelectedTeamId(teamId)}
          value={selectedTeamId}
        />
      </Form.Item>
      <Form.Item
        name="teamMigSelector"
        className="flx--1"
        label={t('TeamMigSelector.teamMigSelectorLabel')}
      >
        <TeamMigSelect
          teamId={selectedTeamId}
          disabled={!selectedTeamId}
          statusesToInclude={[MigStatus.ACTIVE, MigStatus.UPCOMING]}
          onSetTeamMig={(teamMig) => setTeamMig(teamMig)}
        />
      </Form.Item>
      <div className="flx flx--jc-flx-end mt--xl">
        <Btn
          className="mr"
          onClick={() => {
            setSelectedTeamId(undefined);
            setTeamMig(undefined);
            onCancel?.();
          }}
        >
          {t('TeamMigSelector.cancel')}
        </Btn>
        <Btn
          type="primary"
          disabled={!teamMig}
          onClick={() => {
            setSelectedTeamId(undefined);
            setTeamMig;
            teamMig && onAddTeamMig(teamMig);
          }}
        >
          {t('TeamMigSelector.add')}
        </Btn>
      </div>
    </Form>
  );
};
