import { TeamGantishChart } from './components/TeamGantishChart';

import './TeamGantish.less';
import { TeamGantishLegend } from './components/TeamGantishLegend';
import { useTranslation } from 'react-i18next';
import { TeamGantishSkeleton } from './TeamGantish.Skeleton';
import { gql } from '@apollo/client';
import {
  TeamGantish__InitiativeLightFragment,
  TeamGantish__MilestoneFragment,
} from '../../../../../generated/graphql';

interface Props {
  initiativesWithMilestones: {
    initiative: TeamGantish__InitiativeLightFragment;
    milestones: TeamGantish__MilestoneFragment[];
  }[];
  unassociatedMilestones: TeamGantish__MilestoneFragment[];
}

export const TeamGantish = ({
  initiativesWithMilestones,
  unassociatedMilestones,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="TeamGantish">
      <h3>{t('TeamGantish.title')}</h3>
      <div className="flx">
        <TeamGantishLegend
          initiativesWithMilestones={initiativesWithMilestones}
          unassociatedMilestones={unassociatedMilestones}
        />
        <div className="TeamGantish__chart">
          <TeamGantishChart
            initiativesWithMilestones={initiativesWithMilestones}
            unassociatedMilestones={unassociatedMilestones}
          />
        </div>
      </div>
    </div>
  );
};

TeamGantish.Skeleton = TeamGantishSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GIGANTISH__INITIATIVE_LIGHT = gql`
  fragment teamGantish__InitiativeLight on InitiativeLight {
    id
    domainId {
      itemId
      tenantId
    }
    ...teamGantishLegend__InitiativeLight
    ...TeamGantishChart__InitiativeLight
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GIGANTISH__MILESTONES = gql`
  fragment teamGantish__Milestone on Milestone {
    id
    ...teamGantishLegend__Milestone
    ...TeamGantishChart__Milestone
  }
`;
