import { useTranslation } from 'react-i18next';

export const useSkaValidationRules = () => {
  const { t } = useTranslation();

  return {
    name: [
      {
        required: true,
        message: t('MitemForm.fieldRequired', {
          field: t('common.title'),
        }),
      },
      {
        max: MAX_NAME_LENGTH,
        message: t('MitemForm.fieldMaxLength', {
          field: t('common.title'),
          max: MAX_NAME_LENGTH,
        }),
      },
    ],

    definitionOfDone: [
      {
        required: true,
        message: t('MitemForm.fieldRequired', {
          field: t('MitemForm.dodLabel'),
        }),
      },
    ],

    deadline: [
      {
        required: true,
        message: t('MitemForm.fieldRequired', {
          field: t('MitemForm.deadlineLabel'),
        }),
      },
    ],

    ownerId: [
      {
        required: true,
        message: t('MitemForm.fieldRequired', {
          field: t('MitemForm.ownerLabel'),
        }),
      },
    ],

    supportsMigIds: (options: { migRequired: boolean }) => [
      {
        required: options.migRequired,
        message: t('MitemForm.fieldRequired', {
          field: t('MitemForm.migLabel'),
        }),
      },
    ],

    noMigAssociation: (options: { NoMigAssociationRequired: boolean }) => [
      {
        required: options.NoMigAssociationRequired,
        message: t('MitemForm.fieldRequired', {
          field: t('MitemForm.noMigReasonLabel'),
        }),
      },
    ],

    supportsInitiativeIds: (options: { initiativeRequired: boolean }) => [
      {
        required: options.initiativeRequired,
        message: t('MitemForm.fieldRequired', {
          field: t('MitemForm.initiativeLabel'),
        }),
      },
    ],
  };
};

const MAX_NAME_LENGTH = 120;
