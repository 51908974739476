import { useMutation } from '@apollo/client';
import { Form, Modal } from 'antd';
import gql from 'graphql-tag';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../../../../components/ErrorAlert';
import { ReportSprintKeyActivityAchievement } from '../../../../components/ReportSprintKeyActivityAchievement';
import {
  AchievementStatus,
  EditMitemAchievementDocument,
  GetUserAchievementsDocument,
  UserMitemAchievementFragment,
} from '../../../../generated/graphql';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { EditOutlined } from '@ant-design/icons';

interface Props {
  achievement: UserMitemAchievementFragment;
  tenantId?: string;
}

export const EditMitemAchievementModal = ({ achievement, tenantId }: Props) => {
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { mitem } = achievement;

  const [updateAchievement, { loading, error }] = useMutation(
    EditMitemAchievementDocument,
    {
      refetchQueries: (res) => {
        if (!res.data?.editMitemAchievement) return [];
        const queries = [
          {
            query: GetUserAchievementsDocument,
            variables: {
              userId: res.data.editMitemAchievement.userId,
              achievementStatus: AchievementStatus.DELETABLE_BY_USER,
              tenantId,
            },
          },
        ];
        if (tenantId) {
          queries.push({
            query: GetUserAchievementsDocument,
            variables: {
              userId: res.data.editMitemAchievement.userId,
              achievementStatus: AchievementStatus.NOT_DELETABLE_BY_USER,
              tenantId,
            },
          });
        }
        return queries;
      },
      onCompleted: () => {
        setShowModal(false);

        showNotification('success', {
          message: <strong>{t('EditAchievementModal.success')}</strong>,
        });
      },
    }
  );

  const handleSave = () => {
    form.validateFields().then((values) => {
      if (!achievement) return;

      updateAchievement({
        variables: {
          achievementId: achievement.id,
          mitemAchievement: {
            completed: values.completed ?? true,
            description: values.achievementDescription,
          },
          tenantId,
        },
      });
    });
  };

  const handleClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  return (
    <>
      <EditOutlined
        className="txt--action-blue"
        onClick={() => {
          setShowModal(true);
        }}
      />

      <Modal
        maskClosable={false}
        title={t('EditAchievementModal.modalTitle') + ': ' + mitem?.name}
        open={showModal}
        footer={null}
        onCancel={handleClose}
        okText="Save"
      >
        <ReportSprintKeyActivityAchievement
          form={form}
          mitem={mitem}
          onSubmit={handleSave}
          loading={loading}
          initialValues={achievement}
          permittedToComplete={achievement.permittedToComplete}
          latestAchievement={achievement.latestAchievement}
        />

        <ErrorAlert title={t('EditAchievementModal.error')} error={error} />
      </Modal>
    </>
  );
};

export const UPDATE_MITEM_ACHIEVEMENT = gql`
  mutation editMitemAchievement(
    $achievementId: ID!
    $mitemAchievement: EditMitemAchievementInput!
    $tenantId: ID
  ) {
    editMitemAchievement(
      achievementId: $achievementId
      mitemAchievement: $mitemAchievement
      tenantId: $tenantId
    ) {
      mitem {
        id
        name
        deadline
      }
      permittedToComplete
      latestAchievement
      description
      created
      completed
      id
      userId
      team {
        id
        name
      }
    }
  }
`;
