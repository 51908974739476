import { gql, useMutation } from '@apollo/client';
import { Col, Form, Input, Row, Typography } from 'antd';
import { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import {
  ContributorsGraphKeyActivityCommitmentsAndAchievementsDocument,
  CreateWeekliesAchievementDocument,
  GetAkpiAchievementsDocument,
  GetTeamKeyActivitiesWithoutSprintsDocument,
  ReportDrawer2Document,
  TimePeriodFilter,
} from '../../../../../generated/graphql';
import { TEAM_COMMITMENTS_AND_ACHIEVEMENTS } from '../../../../../hooks/useTeamCommitmentAndAchievementV2';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { Btn } from '../../../../Button';
import { DisplayDate } from '../../../../DisplayDate';
import { ErrorAlert } from '../../../../ErrorAlert';
import { NumberInput } from '../../../../NumberInput';
import { RenderedMarkdown } from '../../../../RenderedMarkdown';
import { WeeklyKA } from '../../reportDrawerUtils';

interface Props {
  weeklyKA: WeeklyKA;
  teamId: string;
  onReported: () => void;
  onCancel: () => void;
}

export const WeekliesReportForm = ({
  weeklyKA,
  teamId,
  onReported,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  const { quantityRules, descriptionRules } = useValidationRules(weeklyKA);

  const { handleSubmit, mutationResult } = useSubmitHandler({
    teamId,
    weeklyKA,
    onReported,
  });

  return (
    <div className="SprintReportForm">
      <Row className="mb--l">
        <Col span={5} className="SprintReportForm__label">
          {t('common.deadline')}
        </Col>
        <Col span={19}>
          <DisplayDate date={weeklyKA.periodEndDate} alwaysIncludeYear />
        </Col>
      </Row>
      <Row className="mb--l">
        <Col span={5} className="SprintReportForm__label">
          {t('WeekliesReportForm.unit')}
        </Col>
        <Col span={19}>{weeklyKA.unit}</Col>
      </Row>
      <Row className="mb--l">
        <Col span={5} className="SprintReportForm__label">
          {t('common.commitment', { count: weeklyKA.progress.commitment ?? 0 })}
        </Col>
        <Col span={19}>{weeklyKA.progress.commitment ?? 0}</Col>
      </Row>

      <div className="SprintReportForm__label">
        {t('common.definitionOfDone')}
      </div>
      <Typography.Paragraph
        className="mb--l"
        ellipsis={{ rows: 2, expandable: true }}
      >
        <RenderedMarkdown html={weeklyKA.definitionOfDoneHtml} />
      </Typography.Paragraph>

      <div className="SprintReportForm__label">
        {t('SprintReportForm.commitmentNote')}
      </div>
      <Typography.Paragraph
        className="mb--l preserveLinebreaks"
        ellipsis={{ rows: 2, expandable: true }}
      >
        {weeklyKA.progress.note}
      </Typography.Paragraph>

      <Form
        layout="vertical"
        className="mt--xs"
        hideRequiredMark
        onFinish={handleSubmit}
      >
        <Form.Item
          name="quantity"
          validateTrigger="onBlur"
          className="mb--s"
          label={<div>{t('WeekliesReportForm.quantity.label')}</div>}
          rules={quantityRules}
        >
          <NumberInput min={0} controls={true} autoFocus />
        </Form.Item>
        <Form.Item
          name="description"
          label={t('WeekliesReportForm.achievementDescription.label')}
          rules={descriptionRules}
        >
          <Input.TextArea
            autoComplete="off"
            rows={5}
            showCount
            maxLength={ACHIEVEMENT_DESCRIPTION_MAX_LENGTH}
          />
        </Form.Item>
        <ErrorAlert error={mutationResult.error} />

        <Btn
          type="callToAction"
          loading={mutationResult.loading}
          htmlType="submit"
          data-intercom-target="Report Weekly Key Activity Submit Button"
        >
          {t('WeekliesReportForm.reportButton')}
        </Btn>
        <Btn
          onClick={onCancel}
          htmlType="reset"
          loading={mutationResult.loading}
          type="text"
          className="ml--xl"
        >
          {t('common.cancel')}
        </Btn>
      </Form>
    </div>
  );
};

const ACHIEVEMENT_DESCRIPTION_MAX_LENGTH = 1000;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REPORT_ACHIEVEMENT = gql`
  mutation createWeekliesAchievement(
    $teamId: ID!
    $akpiId: ID!
    $akpiAchievement: CreateAkpiAchievementInput
  ) {
    createAkpiAchievement(
      teamId: $teamId
      akpiId: $akpiId
      akpiAchievement: $akpiAchievement
    ) {
      id
    }
  }
`;

function useSubmitHandler({
  weeklyKA,
  onReported,
  teamId,
}: {
  weeklyKA: WeeklyKA;
  onReported: () => void;
  teamId: string;
}) {
  const { t } = useTranslation();

  const [reportAchievement, mutationResult] = useMutation(
    CreateWeekliesAchievementDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: t('ReportCard.success'),
        });
        onReported();
        (window as any).Intercom?.('update', {
          last_reported_weekly_key_activity_at: new Date(),
          last_reported_key_activity_at: new Date(),
        });
      },
      refetchQueries: () => [
        {
          query: ReportDrawer2Document,
        },
        {
          query: GetTeamKeyActivitiesWithoutSprintsDocument,
          variables: { teamId },
        },
        {
          query: TEAM_COMMITMENTS_AND_ACHIEVEMENTS,
          variables: {
            teamId: teamId,
            timePeriodFilter: TimePeriodFilter.CURRENT,
          },
        },
        {
          query: ContributorsGraphKeyActivityCommitmentsAndAchievementsDocument,
          variables: {
            teamId,
            weeklyKeyActivityId: weeklyKA.weeklyKeyActivityId,
          },
        },
        {
          query: GetAkpiAchievementsDocument,
          variables: {
            teamId,
            akpiId: weeklyKA.weeklyKeyActivityId,
          },
        },
      ],
    }
  );

  const handleSubmit = (values: { quantity: number; description: string }) => {
    return reportAchievement({
      variables: {
        teamId: teamId,
        akpiId: weeklyKA.weeklyKeyActivityId,
        akpiAchievement: {
          quantity: values.quantity,
          description: values.description,
        },
      },
    });
  };

  return { handleSubmit, mutationResult };
}

function useValidationRules(weeklyKA: WeeklyKA) {
  const { t } = useTranslation();

  const quantityRules: Rule[] = [
    {
      required: true,
      message: t('WeekliesReportForm.quantity.unitValidationMessage', {
        unit: weeklyKA.unit,
      }),
    },
    {
      validator: (_, value) => {
        if (value < 0) {
          return Promise.reject(
            t('WeekliesReportForm.rules.noNegativeProgress')
          );
        }

        return Promise.resolve();
      },
    },
    {
      validator: (_, value) => {
        if (!value) return Promise.resolve();
        const regex = /^\d{1,10}([.,]\d*)?$/;

        if (!regex.test(value.toString())) {
          return Promise.reject(t('WeekliesReportForm.rules.toLongValue'));
        }

        return Promise.resolve();
      },
    },
    {
      validator: (_, value) => {
        if (!value) return Promise.resolve();
        const regex = /^\d*([.,]\d{1,6})?$/;

        if (!regex.test(value.toString())) {
          return Promise.reject(t('WeekliesReportForm.rules.toManydecimals'));
        }

        return Promise.resolve();
      },
    },
  ];

  const descriptionRules: Rule[] = [
    {
      required: weeklyKA.achievementDescriptionMandatory,
      message: t('WeekliesReportForm.achievementDescription.mandatory'),
    },
    {
      max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
      message: t('WeekliesReportForm.achievementDescription.maxLength', {
        max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
      }),
    },
  ];

  return { quantityRules, descriptionRules };
}

export const GET_TEAM_KEY_ACTIVITIES_WITHOUTS_SPRINTS = gql`
  fragment TeamAkpiPreviews_WeeklyKeyActivity on WeeklyKeyActivity {
    id
    teamId
    name
    descriptionHtml
    acceptanceCriteriaHtml
    currentPeriodIndex
    startDate
    endDate
    unit
    status
    archivedAt
    periodData {
      targetDate
      goal
      actual
    }
    supportsMigsDetails {
      id
      name
      lastOutcomeDate
      domainId {
        itemId
        tenantId
        teamId
      }
      team {
        id
        name
      }
    }
  }

  fragment TeamAkpiPreviews_MitemTerm on MitemTerm {
    id
    sprintInterval
    startDate
    endDate
    publishDate
    unpublishDate
    status
    published
    periodData {
      targetDate
      goal
      actual
    }
  }
  query getTeamKeyActivitiesWithoutSprints(
    $teamId: ID!
    $statusFilter: PublishStatus
  ) {
    weeklyKeyActivities(teamId: $teamId, statusFilter: $statusFilter) {
      ...TeamAkpiPreviews_WeeklyKeyActivity
    }
    currentMitemTerm(teamId: $teamId) {
      ...TeamAkpiPreviews_MitemTerm
    }
  }
`;
