import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../../../../Auth0Provider';
import './UserMenu.less';
import { TenantSelector } from './components/TenantSelector';
import { friendlyUsername } from '../../../../services/friendlyUsername';
import { CopyText } from '../../../CopyText';
import { useTenantOverride } from '../../../../TenantOverrideProvider';
import { ExternalLinkIcon } from '../../assets/ExternalLinkIcon';
import { UserAvatar } from '../../../UserAvatar';
import { Btn } from '../../../Button';
import { useMe } from '../../../../hooks/useMe';
import { useMyPermissionRoles } from '../../../../usePermissions';

export const UserMenu = () => {
  const { t } = useTranslation();
  const { logout, getTokenSilently } = useAuth0();
  const { isSuperAdmin, isTenantAdmin } = useMyPermissionRoles();

  const powerToolsEnabled = localStorage.getItem('powerTools') === 'true';

  const handleClick = (clicked: MenuInfo) => {
    if (clicked.key === 'logoutAction') {
      (window as any).Intercom?.('shutdown');
      logout();
    }
  };
  const match = useRouteMatch<{ teamId?: string }>('/team/:teamId');
  const teamId = match?.params?.teamId;
  const { tenantId } = useTenantOverride();
  const { data } = useMe();

  const isAdmin = isSuperAdmin || isTenantAdmin;

  type MenuItem = Required<MenuProps>['items'][number];

  const adminMenuGroup: MenuItem[] = [
    {
      key: 'adminControls',
      label: t('UserMenu.menuGroupCompany'),
      type: 'group',
      children: [
        {
          key: 'tenantSelector',
          label: data && (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <TenantSelector userTenant={data.me.tenant.domainId.tenantId} />
            </div>
          ),
        },
        {
          key: 'backofficeButton',
          label: (
            <div>
              <Btn
                href={
                  teamId
                    ? `/admin/company/${tenantId}/team/${teamId}`
                    : `/admin/company/${tenantId}`
                }
                target="_blank"
                icon={<ExternalLinkIcon />}
                type="primary"
                className="fullWidth"
              >
                {t('UserMenu.backofficeButton')}
              </Btn>
            </div>
          ),
        },
        {
          key: 'copySharelink',
          label: powerToolsEnabled ? (
            <div>
              <CopyText
                text={`${window.location.href}?tenantId=${tenantId}`}
                buttonText="Share-link"
                successText="Share-link copied!"
              />
              <CopyText
                text={tenantId!}
                buttonText="TenantID"
                successText="TenantId copied!"
              />
              <CopyText
                onClick={() => {
                  return getTokenSilently();
                }}
                buttonText="Token"
                successText="Token copied!"
              />
            </div>
          ) : (
            <CopyText
              className="fullWidth"
              text={`${window.location.href}?tenantId=${tenantId}`}
              buttonText={t('UserMenu.copySharelink')}
            />
          ),
        },
      ],
    },
    {
      type: 'divider',
    },
  ];

  const baseMenuItems: MenuItem[] = [
    {
      key: 'settingsAction',
      label: (
        <Link to="/my-page/settings" data-intercom-target="User Settings">
          <SettingOutlined className="mr--s" />
          {t('UserMenu.settingsAction')}
        </Link>
      ),
    },
    {
      key: 'logoutAction',
      label: (
        <div data-intercom-target="User Log Out">
          <LogoutOutlined className="mr--s" />
          {t('UserMenu.logoutAction')}
        </div>
      ),
    },
  ];

  const menuItems = isAdmin
    ? [...adminMenuGroup, ...baseMenuItems]
    : baseMenuItems;

  return (
    <Dropdown
      menu={{ items: menuItems, onClick: handleClick }}
      trigger={['click']}
    >
      <span
        className="clickable UserMenu"
        data-intercom-target="User Name Dropdown Navigation"
        title={data?.me ? friendlyUsername(data?.me) : ''}
      >
        <UserAvatar user={data?.me} />
      </span>
    </Dropdown>
  );
};
