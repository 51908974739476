import { gql, useQuery } from '@apollo/client';
import { Divider, FormInstance, Modal, Select } from 'antd';
import { GetAlignToMigSelector_MigsDocument } from '../../../generated/graphql';
import { Btn } from '../../Button';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamMigSelector } from './TeamMigSelector';

type TeamMig = {
  id: string;
  name: string;
  domainId: { itemId: string; teamId: string };
};

interface Props {
  form: FormInstance<any>;
  id?: string;
  value?: string;
  onChange?: (migId: string) => void;
  className?: string;
  disabled?: boolean;
}

export const AlignToMigSelector = ({
  form,
  id,
  value,
  onChange,
  className,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const [teamMig, setTeamMig] = useState<TeamMig | undefined>(undefined);

  const [open, setOpen] = useState(false);
  const [showTeamSelect, setShowTeamSelect] = useState(false);
  const { data } = useQuery(GetAlignToMigSelector_MigsDocument);
  const migs = data?.globalMigs.globalMigs || [];

  const options = [
    {
      label: <span>{t('AlignToMigSelector.companyMigs')}</span>,
      options: migs.map((m) => ({
        value: m.id,
        label: m.name,
      })),
    },
    {
      label: <span>{t('AlignToMigSelector.teamMigs')}</span>,
      exclude: !teamMig,
      options: [{ label: teamMig?.name, value: teamMig?.id }],
    },
  ];

  return (
    <>
      <Modal
        open={showTeamSelect}
        footer={null}
        destroyOnClose
        onCancel={() => setShowTeamSelect(false)}
      >
        <TeamMigSelector
          onCancel={() => setShowTeamSelect(false)}
          onAddTeamMig={(mig) => {
            setTeamMig(mig);
            form.setFieldsValue({ mig: mig.id });
            setShowTeamSelect(false);
          }}
        />
      </Modal>

      <Select
        id={id}
        className={className}
        options={options.filter((o) => !o.exclude)}
        value={value}
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        onChange={onChange}
        disabled={disabled}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <div className="flx flx--jc-center">
              <Btn
                className="mb"
                onClick={() => {
                  setShowTeamSelect(true);
                  setOpen(false);
                }}
              >
                {t('AlignToMigSelector.addTeamMig')}
              </Btn>
            </div>
          </>
        )}
      />
    </>
  );
};

export const GET_GLOBAL_MIGS_TO_ALIGN_TO = gql`
  query GetAlignToMigSelector_Migs {
    globalMigs {
      globalMigs {
        id
        name
        domainId {
          itemId
          teamId
        }
      }
    }
  }
`;
