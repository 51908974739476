import Skeleton from 'react-loading-skeleton';

export const MilestoneActivityTableRowSkeleton = () => {
  return (
    <tr>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td className="text-c">
        <Skeleton />
      </td>
      <td className="text-c">
        <Skeleton />
      </td>
    </tr>
  );
};
