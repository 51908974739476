import {
  AllocationAndProgressCardSkaTimeStatusFragment,
  MitemStatus,
} from '../../../../generated/graphql';
import { toMapV2 } from '../../../../services/toMap';
import {
  calculateDoneVsPlan,
  filterExpectedToBeDoneSkas,
} from '../../common/utils/teamPageHelpers';
import {
  ActivitiesExpectedToBeDonePerGroup,
  ActivitiesPerGroup,
  AlignmentPercentagePerGroup,
  BaseTypeForTeamPageCalc,
  CompletedActivitiesPerGroup,
  DoneVsPlanPerGroup,
  OverdueActivitiesPerGroup,
  UpcomingActivitiesPerGroup,
} from './calculationTypes';

type CalcTypesWithInitiativeTag<T> = T & {
  tag: { iconId: string; colorCode: string; title: string };
};

type InitiativeCalculationTypes = {
  BaseType: CalcTypesWithInitiativeTag<BaseTypeForTeamPageCalc>;
  ActivitiesPerGroup: CalcTypesWithInitiativeTag<ActivitiesPerGroup>;
  UpcomingActivitiesPerGroup: CalcTypesWithInitiativeTag<UpcomingActivitiesPerGroup>;
  AlignmentPercentagePerGroup: CalcTypesWithInitiativeTag<AlignmentPercentagePerGroup>;
  ActivitiesExpectedToBeDonePerGroup: CalcTypesWithInitiativeTag<ActivitiesExpectedToBeDonePerGroup>;
  CompletedActivitiesPerGroup: CalcTypesWithInitiativeTag<CompletedActivitiesPerGroup>;
  OverdueActivitiesPerGroup: CalcTypesWithInitiativeTag<OverdueActivitiesPerGroup>;
  DoneVsPlanPerGroup: CalcTypesWithInitiativeTag<DoneVsPlanPerGroup>;
};

const calculateTotalActivities = (
  sprint: AllocationAndProgressCardSkaTimeStatusFragment[],
  initiativeId: string
) =>
  sprint.filter((ska) => {
    return ska.sprintKeyActivity?.supportsInitiatives2?.some(
      (supportInitiative) => {
        return supportInitiative.id === initiativeId;
      }
    );
  }).length;

const createNewObjectOfInitiatives = (teamInitiatives?: Initiative[]) => {
  const initiatives: Record<string, InitiativeCalculationTypes['BaseType']> =
    {};
  if (teamInitiatives) {
    for (const i of teamInitiatives) {
      const current = initiatives[i.id] || {
        id: i.id,
        name: i.name,
        tag: i.tag,
      };
      initiatives[i.id] = current;
    }
  }

  return initiatives;
};

const countAlignedActivitiesPerInitiative = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[],
  initiatives: Record<string, InitiativeCalculationTypes['BaseType']>
) => {
  const initiativesWithTotalActivities: Record<
    string,
    InitiativeCalculationTypes['ActivitiesPerGroup']
  > = {};

  Object.entries(initiatives).forEach(
    ([initiativeId, val]) =>
      (initiativesWithTotalActivities[initiativeId] = {
        ...val,
        activities: calculateTotalActivities(sprintKeyActivities, initiativeId),
      })
  );

  return initiativesWithTotalActivities;
};

const calculateTotalUpcomingSkasPerInitiative = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[],
  initiativesWithTotalActivities: Record<
    string,
    InitiativeCalculationTypes['ActivitiesPerGroup']
  >
) => {
  const initiativesWithUpcomingSkas: Record<
    string,
    InitiativeCalculationTypes['UpcomingActivitiesPerGroup']
  > = {};

  Object.entries(initiativesWithTotalActivities).forEach(
    ([initiativeId, val]) =>
      (initiativesWithUpcomingSkas[initiativeId] = {
        ...val,
        upcomingActivities: sprintKeyActivities.filter((ska) => {
          return ska.sprintKeyActivity?.supportsInitiatives2?.some(
            (supportInitiative) => {
              const upcomingSkaWithSupportsInitiative =
                supportInitiative.id === initiativeId &&
                (ska.status === MitemStatus.ACTIVE ||
                  ska.status === MitemStatus.PLANNED);

              return upcomingSkaWithSupportsInitiative;
            }
          );
        }).length,
      })
  );

  return initiativesWithUpcomingSkas;
};

const calculateSupportedInitiativesPercentage = (
  amountOfSKAs: number,
  initiativesWithTotalActivities: Record<
    string,
    InitiativeCalculationTypes['UpcomingActivitiesPerGroup']
  >
) => {
  const supportedInitiativesPercentage: Record<
    string,
    InitiativeCalculationTypes['AlignmentPercentagePerGroup']
  > = {};

  Object.entries(initiativesWithTotalActivities).forEach(
    ([key, val]) =>
      (supportedInitiativesPercentage[key] = {
        ...val,
        percentageOfAllActivities:
          amountOfSKAs === 0 || val.activities === 0
            ? null
            : Math.floor((val.activities / amountOfSKAs) * 100),
      })
  );

  return supportedInitiativesPercentage;
};

const calculateGoalPerInitiative = (
  filteredSprint: AllocationAndProgressCardSkaTimeStatusFragment[],
  supportedInitiativesPercentage: Record<
    string,
    InitiativeCalculationTypes['AlignmentPercentagePerGroup']
  >
) => {
  const supportedInitiativesGoal: Record<
    string,
    InitiativeCalculationTypes['ActivitiesExpectedToBeDonePerGroup']
  > = {};

  Object.entries(supportedInitiativesPercentage).forEach(
    ([initiativeId, val]) =>
      (supportedInitiativesGoal[initiativeId] = {
        ...val,
        goal: calculateTotalActivities(filteredSprint, initiativeId),
      })
  );

  return supportedInitiativesGoal;
};

const calculateTotalCompletedActivitiesPerInitiative = (
  filteredSprint: AllocationAndProgressCardSkaTimeStatusFragment[],
  supportedInitiativesGoal: Record<
    string,
    InitiativeCalculationTypes['ActivitiesExpectedToBeDonePerGroup']
  >
) => {
  const supportedInitiativeCompletedActivities: Record<
    string,
    InitiativeCalculationTypes['CompletedActivitiesPerGroup']
  > = {};

  Object.entries(supportedInitiativesGoal).forEach(
    ([initiativeId, val]) =>
      (supportedInitiativeCompletedActivities[initiativeId] = {
        ...val,
        completedActivities: filteredSprint.filter((ska) => {
          return ska.sprintKeyActivity?.supportsInitiatives2?.some(
            (supportInitiative) => {
              return (
                supportInitiative.id === initiativeId &&
                ska.status === MitemStatus.COMPLETED
              );
            }
          );
        }).length,
      })
  );

  return supportedInitiativeCompletedActivities;
};

const countOverdueActivitiesPerInitiative = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[],
  supportedInitiativeCompletedActivities: Record<
    string,
    InitiativeCalculationTypes['CompletedActivitiesPerGroup']
  >
) => {
  const initiativeOverdueActivities: Record<
    string,
    InitiativeCalculationTypes['OverdueActivitiesPerGroup']
  > = {};

  const isOverdueAndSupportsInitiative = (
    ska: AllocationAndProgressCardSkaTimeStatusFragment,
    initiativeId: string
  ) => {
    return ska.sprintKeyActivity?.supportsInitiatives2?.some(
      (supportInitiative) => {
        return (
          supportInitiative.id === initiativeId &&
          ska.status === MitemStatus.OVERDUE
        );
      }
    );
  };

  Object.entries(supportedInitiativeCompletedActivities).forEach(
    ([initiativeId, val]) => {
      const overdueActivitiesSupportingInitiative = sprintKeyActivities.filter(
        (ska) => isOverdueAndSupportsInitiative(ska, initiativeId)
      );

      return (initiativeOverdueActivities[initiativeId] = {
        ...val,
        overdueActivities: overdueActivitiesSupportingInitiative.length,
      });
    }
  );

  return initiativeOverdueActivities;
};

const initiativeDoneVsPlan = (
  initiativeOverdueActivities: Record<
    string,
    InitiativeCalculationTypes['OverdueActivitiesPerGroup']
  >
) => {
  const initiativeData: Record<
    string,
    InitiativeCalculationTypes['DoneVsPlanPerGroup']
  > = {};

  Object.entries(initiativeOverdueActivities).forEach(
    ([key, val]) =>
      (initiativeData[key] = {
        ...val,
        doneVsPlan:
          val.percentageOfAllActivities === null
            ? null
            : calculateDoneVsPlan(val.completedActivities, val.goal),
      })
  );

  return initiativeData;
};

interface Initiative {
  id: string;
  name: string;
  tag: {
    iconId: string;
    colorCode: string;
    title: string;
  };
}

interface Milestone {
  id: string;
  domainId: {
    itemId: string;
  };
  name: string;
  metadata: {
    supports: {
      type: string;
      item: {
        id: string;
        domainId: {
          itemId: string;
        };
      };
    }[];
  };
}

export const getInitiativeData = (
  sprintKeyActivities: AllocationAndProgressCardSkaTimeStatusFragment[],
  tenantInitiatives: Initiative[],
  teamMilestones: Milestone[]
) => {
  const initiatives = createNewObjectOfInitiatives(tenantInitiatives);

  const msInitLookup = toMapV2(
    teamMilestones,
    (milestone) => milestone.domainId.itemId,
    (milestone) =>
      milestone.metadata.supports.find((si) => si.type === 'initiative')?.item
        .id
  );

  // todo: remove/rewrite this when initiatives 2.0 are GA and fully rolled out
  const normalizedSkas = sprintKeyActivities.map((ska) => {
    if (ska.sprintKeyActivity == null) return ska;

    const supportsInitiatives2 = ska.sprintKeyActivity.supportsInitiatives.map(
      (init) => ({
        __typename: 'TenantInitiativeLink' as const,
        id: init.id,
      })
    );

    for (const sm of ska.sprintKeyActivity.supportsMilestones) {
      const supportedInitViaMilestone = msInitLookup[sm.domainId.itemId];

      if (supportedInitViaMilestone) {
        supportsInitiatives2.push({
          __typename: 'TenantInitiativeLink' as const,
          id: supportedInitViaMilestone,
        });
      }
    }

    return {
      ...ska,
      sprintKeyActivity: {
        ...ska.sprintKeyActivity,
        supportsInitiatives2,
      },
    };
  });

  const sumActivitiesPerInitiative = countAlignedActivitiesPerInitiative(
    normalizedSkas,
    initiatives
  );

  const sumUpcomingActivitiesPerInitiative =
    calculateTotalUpcomingSkasPerInitiative(
      normalizedSkas,
      sumActivitiesPerInitiative
    );

  const supportedInitiativesPercentage =
    calculateSupportedInitiativesPercentage(
      normalizedSkas.length,
      sumUpcomingActivitiesPerInitiative
    );

  const filteredSkasExpectedToBeDone =
    filterExpectedToBeDoneSkas(normalizedSkas);

  const supportedInitiativesGoal = calculateGoalPerInitiative(
    filteredSkasExpectedToBeDone,
    supportedInitiativesPercentage
  );

  const supportedInitiativesTotalCompletedActivities =
    calculateTotalCompletedActivitiesPerInitiative(
      normalizedSkas,
      supportedInitiativesGoal
    );

  const initiativesOverdueActivities = countOverdueActivitiesPerInitiative(
    normalizedSkas,
    supportedInitiativesTotalCompletedActivities
  );

  const initiativeData = initiativeDoneVsPlan(initiativesOverdueActivities);

  const completeInitiativeData = Object.values(initiativeData).map((val) => ({
    id: val.id,
    name: val.name,
    amountOfSKAs: val.activities,
    percentageOfAllActivities: val.percentageOfAllActivities,
    doneVsPlan: val.doneVsPlan,
    initiativeTag: val.tag,
    completed: val.completedActivities,
    upcomingActivities: val.upcomingActivities,
    overdueActivities: val.overdueActivities,
  }));

  return completeInitiativeData;
};
