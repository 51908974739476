import './GantishLegend.less';
import { MilestoneStats } from './GantishLegend/MilestoneStats';
import { LegendSubInitiative } from './GantishLegend/LegendSubInitiative';
import { useTranslation } from 'react-i18next';
import { GantishLegendSkeleton } from './GantishLegend.skeleton';
import { gql } from '@apollo/client';
import { InitiativeDetailsTreeNode } from '../../../../appPages/company/initiatives/initiativeDetails/InitiativeDetails';

interface Props {
  initiativeNode: InitiativeDetailsTreeNode;
  expanded: string[];
  onToggleExpanded: (id: string) => void;
}

export const GantishLegend = (props: Props) => {
  const { t } = useTranslation();
  const { children, data } = props.initiativeNode;

  return (
    <div className="GantishLegend">
      <div className="GantishLegend__row">
        <h5 className="txt--secondary">{t('GantishLegend.title')}</h5>
        <MilestoneStats.Header />
      </div>

      <div className="GantishLegend__row">
        <h4 title={data.gantishInitiative.tag.title}>
          {t('GantishLegend.thisInitiative')}
        </h4>
        <MilestoneStats initiativeNode={props.initiativeNode} isExpanded />
      </div>

      <div className="GantishLegend__row GantishLegend__row--spacer">
        <h4 className="mb">{t('GantishLegend.connectedInitiatives')}</h4>
      </div>

      {children?.map((subInitiative) => (
        <LegendSubInitiative
          initiativeNode={subInitiative}
          key={subInitiative.id}
          expanded={props.expanded}
          onToggleExpanded={props.onToggleExpanded}
        />
      ))}
    </div>
  );
};

GantishLegend.Skeleton = GantishLegendSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GANTISH_LEGEND__INITIATIVE2 = gql`
  fragment gantishLegend__Initiative2 on Initiative2 {
    id
    tag {
      title
    }
  }
`;
