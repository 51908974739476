import { ApolloError } from '@apollo/client';
import { Divider, Form, FormInstance, Input, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { TitleWithTooltip } from '../appPages/team/setup/components/TitleWithTooltip';
import { friendlyDate } from '../services/dateFormats';
import { momentToDayjs } from '../services/dateHelpers';
import { ErrorAlert } from './ErrorAlert';
import { Btn } from './Button';

interface Props {
  form: FormInstance;
  onSubmit: (values: { quantity: string; description: string }) => void;
  mitem: {
    description?: string | null;
    completed: boolean;
    definitionOfDone: string;
    deadline: string;
  };
  permittedToComplete?: boolean;
  latestAchievement?: boolean;
  error?: ApolloError;
  loading: boolean;
  initialValues?: { completed: boolean; description?: string | null };
  completed?: boolean;
  note?: string | null;
  committed?: boolean;
  committedDeadline?: string | Moment;
}

const ACHIEVEMENT_DESCRIPTION_MAX_LENGTH = 1000;
const { Text } = Typography;

export const ReportSprintKeyActivityAchievement = ({
  form,
  initialValues,
  mitem,
  onSubmit,
  permittedToComplete,
  error,
  loading,
  latestAchievement = true,
  committed,
  committedDeadline,
  note,
}: Props) => {
  const { t } = useTranslation();

  const isEdit = initialValues != null;

  return (
    <div>
      <div className="mb">
        <div className="mb">
          <div>
            <Text type="secondary" className="space--r">
              {t('MitemReport.definitionOfDone')}
            </Text>
            <Text
              type="secondary"
              className="ReportSprintKeyActivityAchievement__labelDeadline"
            >
              {t('MitemReport.labelDeadline', {
                deadline: friendlyDate(mitem.deadline),
              })}
            </Text>
          </div>
          <div>
            <Text style={{ whiteSpace: 'pre-wrap' }}>
              {mitem.definitionOfDone}
            </Text>
          </div>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        className="mt--xs"
        hideRequiredMark
        initialValues={
          initialValues
            ? {
                achievementDescription: initialValues.description,
                ...initialValues,
              }
            : { completed: false }
        }
        onFinish={onSubmit}
      >
        {permittedToComplete && (
          <>
            <Form.Item
              name="completed"
              label={
                <Text type="secondary">
                  <TitleWithTooltip
                    title={t('MitemReport.mitemStatus')}
                    tooltipContent={t('MitemReport.mitemStatusTooltip')}
                  />
                </Text>
              }
              valuePropName="checked"
            >
              <Checkbox disabled={!latestAchievement}>
                {t('MitemReport.mitemStatusCompleted')}
              </Checkbox>
            </Form.Item>
            {!latestAchievement && (
              <div
                style={{
                  fontStyle: 'italic',
                  marginTop: -25,
                  marginBottom: 16,
                }}
              >
                {t('MitemReport.mitemStatusUpdateInfo')}
              </div>
            )}
          </>
        )}
        {committed && committedDeadline && (
          <>
            <Divider style={{ margin: '16px 0' }} />
            <div className="mb">
              <div className="flx" style={{ alignItems: 'baseline' }}>
                <Text type="secondary" className="space--r">
                  {t('MitemReport.commitmentNote')}
                </Text>
                <Text
                  type="secondary"
                  className="ReportSprintKeyActivityAchievement__labelDeadline"
                >
                  {t('MitemReport.labelDeadline', {
                    deadline: friendlyDate(momentToDayjs(committedDeadline)),
                  })}
                </Text>
              </div>
              <div>
                <Text style={{ whiteSpace: 'pre-wrap' }}>
                  {note == null || note === '' ? (
                    <div className="italic">
                      - {t('MitemReport.commitmentWithoutNote')}
                    </div>
                  ) : (
                    note
                  )}
                </Text>
              </div>
            </div>
            <Divider style={{ margin: '16px 0' }} />
          </>
        )}
        {!committed && note && (
          <>
            <Divider style={{ margin: '16px 0' }} />
            <div className="mb">
              <Text type="secondary" className="space--r">
                {t('MitemReport.note')}
              </Text>
              <div>
                <Text style={{ whiteSpace: 'pre-wrap' }}>{note}</Text>
              </div>
            </div>
            <Divider style={{ margin: '16px 0' }} />
          </>
        )}
        <Form.Item
          name="achievementDescription"
          label={
            <Text type="secondary">
              {t('MitemReport.achievementDescription.title')}
            </Text>
          }
          rules={[
            {
              required: true,
              max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
              message: t('ReportCard.achievementDescription.maxLength', {
                max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
              }),
            },
          ]}
        >
          <Input.TextArea
            autoComplete="off"
            rows={5}
            placeholder={t('MitemReport.achievementDescription.placeholder')}
            showCount
            maxLength={ACHIEVEMENT_DESCRIPTION_MAX_LENGTH}
          />
        </Form.Item>
        <Form.Item>
          <ErrorAlert error={error} title={t('MitemReport.saveError')} />
          <Btn
            type="primary"
            htmlType="submit"
            block
            loading={loading}
            data-intercom-target={
              isEdit
                ? 'Edit Report Sprint Key Activity Save Button'
                : 'Report Sprint Key Activity Submit Button'
            }
          >
            {t('MitemReport.reportButton')}
          </Btn>
        </Form.Item>
      </Form>
    </div>
  );
};
