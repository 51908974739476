import { gql } from '@apollo/client';
import cx from 'classnames';
import { MilestoneCard_MilestoneFragment } from '../../../../../../../generated/graphql';
import './MilestoneCard.less';
import { Divider, Modal, Typography } from 'antd';
import { ProgressStats } from '../../../../../../../components/initiatives/ProgressStats';
import { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { InitiativeTag } from '../../../../../../../components/initiative/InitiativeTag';
import { useTranslation } from 'react-i18next';
import { MilestoneCardActivityStats } from './MilestoneCardActivityStats';
import { DisplayDate } from '../../../../../../../components/DisplayDate';
import { MilestoneCardModalContent } from '../../../../../../company/initiatives/initiativeDetails/progressSection/components/milestoneTable/components/milestoneActions/components/MilestoneCardModalContent';
import { Btn } from '../../../../../../../components/Button';

interface Props {
  milestone: MilestoneCard_MilestoneFragment;
  teamId: string;
  onOpenCreateForm: (opts: {
    deadline?: string;
    preselectedMilestone?: {
      id: string;
      domainId: { itemId: string; tenantId: string };
    };
  }) => void;
}

export const MilestoneCard = ({
  milestone,
  teamId,
  onOpenCreateForm,
}: Props) => {
  const { name, metadata } = milestone;
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();
  const { status } = metadata;

  const supportedInitiatives = milestone.metadata.supports.filter(
    (s) => s.__typename === 'SupportedInitiative'
  );

  const tags = supportedInitiatives.map((si) => si.item.tag);

  return (
    <div>
      <Modal
        open={showDetails}
        footer={null}
        width={800}
        onCancel={() => setShowDetails(false)}
      >
        <MilestoneCardModalContent
          milestoneId={milestone.domainId.itemId}
          teamId={teamId}
        />
      </Modal>
      <div
        className={cx('MilestoneCard', `MilestoneCard--${status}`)}
        onClick={() => setShowDetails(true)}
      >
        <div className="flx flx--jc-space-between flx--ai-center">
          <Typography.Text strong>{name}</Typography.Text>
          <div className="txt--secondary">
            <DisplayDate date={milestone.deadlineAt} />
          </div>
        </div>
        <div className="flx flx--gap--xs flx--wrap mt--m txt--secondary flx--jc-space-between">
          {t('MilestoneCard.activityProgress')}
          <ErrorBoundary FallbackComponent={MilestoneCardActivityStats.Error}>
            <Suspense fallback={<ProgressStats.Skeleton />}>
              <MilestoneCardActivityStats
                milestoneId={milestone.domainId.itemId}
                teamId={teamId}
              />
            </Suspense>
          </ErrorBoundary>
        </div>
        <div className="mt">
          {tags.map((tag) => (
            <InitiativeTag key={tag.title} tag={tag} />
          ))}
        </div>
        <Divider className="mt--s mb--s" />
        <div className="flx flx--jc-space-between flx--ai-center">
          <div className="flx">
            <Btn
              size="small"
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                onOpenCreateForm({
                  preselectedMilestone: milestone,
                });
              }}
            >
              {t('MilestoneCard.addActivity')}
            </Btn>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MILESTONE_CARD__MILESTONE = gql`
  fragment MilestoneCard_Milestone on Milestone {
    id
    domainId {
      itemId
      tenantId
    }
    deadlineAt
    description
    name
    metadata {
      completedAt
      status
      supports {
        ... on SupportedInitiative {
          ...MilestoneDetails__SupportedInitiative
        }
      }
    }
  }
`;
