import {
  CalendarOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  LockFilled,
  MessageOutlined,
} from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Spin, Timeline } from 'antd';
import moment from 'moment';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DisplayName } from '../../../../../components/DisplayName';

import { GetMitemEventTimelineDocument } from '../../../../../generated/graphql';
import { friendlyDate } from '../../../../../services/dateFormats';
import { stringSort } from '../../../../../services/stringSort';
import { mitemStatusColor } from '../../../../../styleVars';

import { MitemHistoryEntry } from './mitemHistory/MitemHistoryEntry';

interface Props {
  mitemId: string;
  teamId: string;
}

export const MitemHistory = ({ mitemId, teamId }: Props) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GetMitemEventTimelineDocument, {
    variables: { teamId, mitemId },
    fetchPolicy: 'network-only',
  });

  const historyEntries =
    data?.mitemEventTimeline.timelineEvents
      .reduce(
        (accArray, timeEvent) => {
          if (timeEvent.__typename === 'TimelineEventCreated') {
            return accArray.concat({
              component: (
                <Timeline.Item
                  key={`${accArray.length}`}
                  dot={
                    <div style={{ color: mitemStatusColor.PLANNED }}>
                      <CalendarOutlined />
                    </div>
                  }
                >
                  <MitemHistoryEntry
                    title={
                      <div className="flx">
                        {t('MitemHistory.created', {
                          deadline: timeEvent.deadline
                            ? friendlyDate(timeEvent.deadline)
                            : '',
                        })}
                      </div>
                    }
                    timeStamp={timeEvent.createdAt}
                    user={timeEvent.createdByUser}
                  />
                </Timeline.Item>
              ),
              timeStamp: timeEvent.createdAt,
            });
          }
          if (timeEvent.__typename === 'TimelineEventCommitted') {
            const weeklyCommitments = timeEvent.userCommitments.filter(
              (uc) => uc.committed
            );
            const weeklyNotes = timeEvent.userCommitments.filter(
              (uc) => !uc.committed
            );
            if (weeklyNotes.length > 0) {
              return accArray.concat(
                weeklyNotes.map((wn) => ({
                  component: (
                    <Timeline.Item
                      key={`${accArray.length}`}
                      dot={
                        <div style={{ color: mitemStatusColor.ARCHIVED }}>
                          <MessageOutlined />
                        </div>
                      }
                    >
                      <MitemHistoryEntry
                        title={
                          <div className="flx">
                            {t('MitemHistory.notesWithoutCommitment')}
                          </div>
                        }
                        subTitle={wn.note}
                        footerOverride={
                          <div>
                            <Trans i18nKey="MitemHistoryEntry.assigned">
                              Assigned
                              {wn.committedUser && (
                                <DisplayName user={wn.committedUser} />
                              )}
                              by
                              {timeEvent.createdByUser && (
                                <DisplayName user={timeEvent.createdByUser} />
                              )}
                            </Trans>
                          </div>
                        }
                        timeStamp={timeEvent.createdAt}
                        user={timeEvent.createdByUser}
                      />
                    </Timeline.Item>
                  ),
                  timeStamp: timeEvent.createdAt,
                }))
              );
            }
            if (weeklyCommitments.length > 0) {
              return accArray.concat(
                weeklyCommitments.map((wc) => ({
                  component: (
                    <Timeline.Item
                      key={`${accArray.length}`}
                      dot={
                        <div style={{ color: mitemStatusColor.ARCHIVED }}>
                          <MessageOutlined />
                        </div>
                      }
                    >
                      <MitemHistoryEntry
                        title={
                          <div className="flx">
                            {t('MitemHistory.committed')}
                          </div>
                        }
                        subTitle={weeklyCommitments.map((u, i) => (
                          <div key={i}>{u.note}</div>
                        ))}
                        footerOverride={
                          <div>
                            <Trans i18nKey="MitemHistoryEntry.assigned">
                              assigned to
                              {wc.committedUser && (
                                <DisplayName user={wc.committedUser} />
                              )}
                              by
                              {timeEvent.createdByUser && (
                                <DisplayName user={timeEvent.createdByUser} />
                              )}
                            </Trans>
                          </div>
                        }
                        timeStamp={timeEvent.createdAt}
                        user={timeEvent.createdByUser}
                      />
                    </Timeline.Item>
                  ),
                  timeStamp: timeEvent.createdAt,
                }))
              );
            }
          }
          if (timeEvent.__typename === 'TimelineEventReported') {
            return accArray.concat({
              component: (
                <Timeline.Item
                  key={`${accArray.length}`}
                  dot={
                    <div style={{ color: mitemStatusColor.COMPLETED }}>
                      <CheckCircleOutlined />
                    </div>
                  }
                >
                  <MitemHistoryEntry
                    title={
                      <div className="flx">{t('MitemHistory.reported')}</div>
                    }
                    subTitle={timeEvent.description}
                    timeStamp={timeEvent.createdAt}
                    user={timeEvent.createdByUser}
                  />
                </Timeline.Item>
              ),
              timeStamp: timeEvent.createdAt,
            });
          }
          if (timeEvent.__typename === 'TimelineEventAchieved') {
            return accArray.concat({
              component: (
                <Timeline.Item
                  key={`${accArray.length}`}
                  dot={
                    <div style={{ color: mitemStatusColor.COMPLETED }}>
                      <CheckCircleFilled />
                    </div>
                  }
                >
                  <MitemHistoryEntry
                    title={t('MitemHistory.completed')}
                    timeStamp={timeEvent.achievedAt}
                    subTitle={timeEvent.description}
                    user={timeEvent.createdByUser}
                  />
                </Timeline.Item>
              ),
              timeStamp: timeEvent.achievedAt,
            });
          }
          if (timeEvent.__typename === 'TimelineEventArchived') {
            return accArray.concat({
              component: (
                <Timeline.Item
                  color={mitemStatusColor.ARCHIVED}
                  key={`${accArray.length}`}
                >
                  <MitemHistoryEntry
                    title={
                      <div className="flx">{t('common.archived.single')}</div>
                    }
                    timeStamp={timeEvent.createdAt}
                    user={timeEvent.createdByUser}
                  />
                </Timeline.Item>
              ),
              timeStamp: timeEvent.createdAt,
            });
          }
          if (timeEvent.__typename === 'TimelineEventRescheduled') {
            let sprintActivityEvents = [];
            if (
              (timeEvent.fromDeadline.sprint?.locked ||
                timeEvent.fromDeadline.sprint?.finalized) &&
              timeEvent.fromDeadline.sprint.id !==
                timeEvent.toDeadline.sprint?.id
            ) {
              sprintActivityEvents.push({
                component: (
                  <Timeline.Item
                    color={mitemStatusColor.NOT_COMPLETED}
                    key={`${accArray.length},${sprintActivityEvents.length}`}
                  >
                    <div>
                      <MitemHistoryEntry
                        title={
                          <div className="flx">
                            {t('MitemHistory.notCompletedInSprint', {
                              sprintNumber: timeEvent.fromDeadline.sprint
                                .sprintNumber
                                ? timeEvent.fromDeadline.sprint.sprintNumber + 1
                                : 1,
                            })}
                          </div>
                        }
                        timeStamp={timeEvent.fromDeadline.sprint.endDate}
                        user={timeEvent.createdByUser}
                      />
                    </div>
                  </Timeline.Item>
                ),
                timeStamp: timeEvent.fromDeadline.sprint.endDate,
              });
            }

            sprintActivityEvents.push({
              component: (
                <Timeline.Item
                  dot={
                    <div style={{ color: mitemStatusColor.PLANNED }}>
                      <CalendarOutlined />
                    </div>
                  }
                  key={`${accArray.length},${sprintActivityEvents.length}`}
                >
                  <MitemHistoryEntry
                    title={
                      <div className="flx">
                        {t('MitemHistory.rescheduled', {
                          deadline: timeEvent.toDeadline.deadline
                            ? friendlyDate(timeEvent.toDeadline.deadline)
                            : '',
                        })}
                      </div>
                    }
                    timeStamp={timeEvent.createdAt}
                    user={timeEvent.createdByUser}
                  />
                </Timeline.Item>
              ),
              timeStamp: timeEvent.createdAt,
            });

            if (
              timeEvent.toDeadline.sprint?.locked &&
              timeEvent.toDeadline.sprint.createdAt &&
              timeEvent.fromDeadline.sprint?.id !==
                timeEvent.toDeadline.sprint.id
            ) {
              sprintActivityEvents.push({
                component: (
                  <Timeline.Item
                    dot={<LockFilled />}
                    key={`${accArray.length},${sprintActivityEvents.length}`}
                  >
                    <MitemHistoryEntry
                      title={
                        <div className="flx">
                          {t('MitemHistory.committedToSprint', {
                            sprintNumber: timeEvent.toDeadline.sprint
                              .sprintNumber
                              ? timeEvent.toDeadline.sprint.sprintNumber + 1
                              : 1,
                            sprintStartDate: friendlyDate(
                              timeEvent.toDeadline.sprint.startDate
                            ),
                            sprintEndDate: friendlyDate(
                              timeEvent.toDeadline.sprint.endDate
                            ),
                          })}
                        </div>
                      }
                      timeStamp={
                        moment(timeEvent.toDeadline.sprint.createdAt).isAfter(
                          timeEvent.createdAt
                        )
                          ? timeEvent.toDeadline.sprint.createdAt
                          : timeEvent.createdAt
                      }
                      user={timeEvent.createdByUser}
                    />
                  </Timeline.Item>
                ),
                timeStamp: moment(
                  timeEvent.toDeadline.sprint.createdAt
                ).isAfter(timeEvent.createdAt)
                  ? timeEvent.toDeadline.sprint.createdAt
                  : timeEvent.createdAt,
              });
            }
            return accArray.concat(sprintActivityEvents);
          }
          throw new Error('TimeEvent not supported');
        },
        [] as {
          component: React.ReactNode;
          timeStamp: string;
        }[]
      )
      .sort((a, b) => stringSort(a.timeStamp, b.timeStamp))
      .reverse() ?? [];

  return (
    <Spin spinning={loading}>
      <Timeline>
        {historyEntries.map((timeEvent) => timeEvent.component)}
      </Timeline>
    </Spin>
  );
};

export const MITEM_EVENT_TIMELINE = gql`
  query getMitemEventTimeline($teamId: ID!, $mitemId: ID!) {
    mitemEventTimeline(teamId: $teamId, mitemId: $mitemId) {
      timelineEvents {
        ... on TimelineEventCommitted {
          createdAt
          createdByUser {
            email
            displayName
            name
            id
          }
          userCommitments {
            committed
            note
            committedUser {
              email
              displayName
              name
              id
            }
          }
          timeEvent
        }
        ... on TimelineEventReported {
          createdAt
          createdByUser {
            email
            displayName
            name
            id
          }
          description
          completed
          timeEvent
        }
        ... on TimelineEventAchieved {
          achievedAt
          createdAt
          timeEvent
          completed
          description
          achievedByUser {
            email
            displayName
            name
            id
          }
          createdByUser {
            email
            displayName
            name
            id
          }
        }
        ... on TimelineEventRescheduled {
          timeEvent
          createdByUser {
            email
            displayName
            name
            id
          }
          createdAt
          fromDeadline {
            excluded
            deadline
            sprint {
              id
              startDate
              createdAt
              teamId
              endDate
              locked
              finalized
              sprintNumber
            }
          }
          toDeadline {
            excluded
            deadline
            sprint {
              id
              startDate
              createdAt
              endDate
              teamId
              locked
              finalized
              sprintNumber
            }
          }
        }
        ... on TimelineEventArchived {
          createdAt
          createdByUser {
            email
            displayName
            name
            id
          }
          timeEvent
        }
        ... on TimelineEventCreated {
          deadline
          createdAt
          createdByUser {
            email
            displayName
            name
            id
          }
          timeEvent
        }
      }
    }
  }
`;
