import React from 'react';
import { LineProps } from 'recharts';
import { useTranslation } from 'react-i18next';
import { lineColor } from '../styleVars';
import { KeyActivityGraph } from './graphs/KeyActivityGraph';
import { Moment } from 'moment';

interface PeriodData {
  targetDate: string;
  label: string;
  goal?: number | null;
  actual?: number | null;
  committed?: number | null;
  historic?: number | null;
}

interface Props {
  periodData: PeriodData[];
  activeDot?: number | null;
  unit?: string;
  referenceLine?: string;
  height?: number;
  startDate: string | Moment;
  includeCommitmentLine?: boolean;
  includeHistoricLine?: boolean;
  children?: React.ReactNode;
}

export const PeriodDataGraph: React.FC<Props> = ({
  periodData,
  referenceLine,
  children,
  height = 200,
  unit,
  startDate,
}) => {
  const { t } = useTranslation();

  const lineConf: LineProps[] = [
    {
      dataKey: 'goal',
      stroke: lineColor.goal,
      name: t('common.goal') as string,
    },
    {
      dataKey: 'actual',
      stroke: lineColor.actual,
      name: t('common.actual') as string,
    },
    {
      dataKey: 'committed',
      name: t('common.committed_one'),
      stroke: lineColor.committed,
      strokeDasharray: '4 6',
    },
    {
      dataKey: 'historic',
      name: t('common.historic'),
      stroke: lineColor.historic,
      strokeDasharray: '4 6',
    },
  ];

  return (
    <KeyActivityGraph
      lineData={periodData}
      startDate={startDate}
      height={height}
      lineConf={lineConf}
      highlightedPeriod={referenceLine}
      yLabel={unit}
    >
      {children}
    </KeyActivityGraph>
  );
};
