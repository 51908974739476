import { gql, useMutation, useQuery, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  ArchivedMilestones_ListInitiativesQueryDocument,
  ArchivedMilestones_ListMilestonesQueryDocument,
  ArchivedMilestones_UnarchiveMilestoneMutationDocument,
} from '../../../../generated/graphql';

import { InitiativeSelect } from './manageCompanyInitiatives/InitiativeSelect';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Table } from 'antd';
import { Btn } from '../../../Button';
import { UnarchiveOutlinedIcon } from '../../../../icons/UnarchiveIcon';

export const ArchivedMilestones = () => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ initiativeId: string }>(
    '/company/initiatives/:initiativeId'
  );
  const { data: initiativesData } = useSuspenseQuery(
    ArchivedMilestones_ListInitiativesQueryDocument,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const [selectedInitiative, setSelectedInitiative] = useState(
    () =>
      match?.params.initiativeId ??
      initiativesData.tenantInitiatives2.initiatives[0]?.domainId.itemId
  );

  const { data: milestonesData, loading: loadingMilestones } = useQuery(
    ArchivedMilestones_ListMilestonesQueryDocument,
    {
      variables: {
        initiativeId: selectedInitiative,
      },
    }
  );

  const [unarchive, { loading: unArchivePending }] = useMutation(
    ArchivedMilestones_UnarchiveMilestoneMutationDocument
  );

  const archivedMilestones =
    milestonesData?.tenantInitiative.metadata.supportedByMilestones.filter(
      (t) => t.metadata.archived
    ) ?? [];

  return (
    <div>
      <div className="mb--l">
        <div className="font-size--sm txt--secondary">Select initiative</div>
        <InitiativeSelect
          style={{ width: '300px' }}
          value={selectedInitiative}
          onChange={(init) => {
            setSelectedInitiative(init.domainId.itemId);
          }}
          placeholder={t('ManageCompanyInitiatives.selectInitiative')}
          initiatives={initiativesData.tenantInitiatives2.initiatives}
          className="flx--1"
        />
      </div>
      <h3>{t('ArchivedMilestones.title')}</h3>
      <Table
        loading={loadingMilestones}
        dataSource={archivedMilestones}
        rowKey={(t) => t.id}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            key: 'actions',
            render: (_, milestone) => (
              <Btn
                onClick={() => {
                  unarchive({
                    variables: {
                      milestoneId: milestone.domainId.itemId,
                      milestone: { archived: false, rev: milestone.rev },
                    },
                  });
                }}
                loading={unArchivePending}
                type="link"
                icon={<UnarchiveOutlinedIcon />}
                title={t('UnarchiveButton.unarchive')}
              />
            ),
            width: 100,
          },
        ]}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const QUERY = gql`
  query ArchivedMilestones_listInitiativesQuery($tenantId: ID) {
    tenantInitiatives2(tenantId: $tenantId) {
      initiatives {
        id
        domainId {
          tenantId
          itemId
        }
        name
        tag {
          colorCode
          iconId
          title
        }
        metadata {
          status
          completedAt
          archived
        }
      }
    }
  }

  query ArchivedMilestones_listMilestonesQuery(
    $tenantId: ID
    $initiativeId: ID!
  ) {
    tenantInitiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      domainId {
        tenantId
        itemId
      }
      name
      metadata {
        supportedByMilestones {
          id
          domainId {
            tenantId
            itemId
          }
          rev
          metadata {
            archived
          }
          name
        }
      }
    }
  }

  mutation ArchivedMilestones_UnarchiveMilestoneMutation(
    $milestoneId: ID!
    $milestone: MilestoneUpdateInput!
  ) {
    updateMilestone(milestoneId: $milestoneId, milestone: $milestone) {
      id
      domainId {
        itemId
        tenantId
      }
      metadata {
        archived
      }
    }
  }
`;
