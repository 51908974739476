import { gql, useQuery } from '@apollo/client';
import {
  GetAccelerationMeetingPeriodSummaryDocument,
  GetAccelerationMeetingPeriodSummaryQuery,
} from '../../../../../../../../../generated/graphql';
import { Unboxed } from '../../../../../../../../../services/typeHelpers';

interface Props {
  teamId: string;
  tenantId?: string;
}

export const usePreviousPeriodSummary = ({ teamId, tenantId }: Props) => {
  const { data, error, loading } = useQuery(
    GetAccelerationMeetingPeriodSummaryDocument,
    {
      variables: { teamId, tenantId },
      fetchPolicy: 'cache-and-network',
    }
  );

  const userContributionData =
    data?.accelerationMeetingPeriodSummary.contributionsGroupedByUser;

  return {
    data,
    userContributionData,
    hasFirstMeeting:
      data?.accelerationMeetingPeriodSummary.previousMeetingDate === null,
    hasWeeklyKeyActivities:
      (data?.accelerationMeetingPeriodSummary?.weeklyKeyActivities ?? [])
        .length > 0,
    hasSprintKeyActivities:
      !!data?.accelerationMeetingPeriodSummary?.sprintKeyActivityTerm,
    error,
    loading,
  };
};

export const GET_PREVIOUS_PERIOD_SUMMARY = gql`
  query getAccelerationMeetingPeriodSummary($tenantId: ID, $teamId: ID!) {
    accelerationMeetingPeriodSummary(tenantId: $tenantId, teamId: $teamId) {
      teamId
      startDate
      endDate
      previousMeetingDate
      weeklyKeyActivityTeamContributions {
        id
        name
        totalCommitted
        totalAchieved
      }
      sprintKeyActivityTeamContributions {
        startDate
        endDate
        totalCommitted
        totalAchieved
      }
      sprintKeyActivityTerm {
        id
        teamId
        tenantId
        sprintInterval
        startDate
        endDate
        publishDate
        unpublishDate
        status
        published
        periodData {
          targetDate
          goal
          actual
        }
      }
      weeklyKeyActivities {
        id
        name
        startDate
        endDate
        teamId
        acceptanceCriteriaHtml
        descriptionHtml
        unit
        periodDataWithCommitment {
          targetDate
          goal
          actual
          committed
        }
      }
      contributionsGroupedByUser {
        id
        user {
          id
          name
          displayName
          email
          initials
        }
        weeklyKeyActivities {
          id
          userId
          name
          note
          quantity
          unit
          achievements {
            id
            quantity
            description
            achieved
          }
        }
        sprintKeyActivities {
          id
          userId
          name
          note
          status
          committed
          deadline
          definitionOfDone
          achievements {
            id
            completed
            description
            achieved
          }
        }
        totalCommitted
        totalAchieved
      }
    }
  }
`;

export type UserContributionData = Unboxed<
  GetAccelerationMeetingPeriodSummaryQuery['accelerationMeetingPeriodSummary']['contributionsGroupedByUser']
>;
