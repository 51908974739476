import cx from 'classnames';
import './TeamGantishMilestoneIndicator.less';
import { Popover } from 'antd';
import { groupBy } from '../../../../../../../../services/groupBy';
import { MilestoneIndicator__MilestoneFragment } from '../../../../../../../../generated/graphql';
import { gql } from '@apollo/client';

interface Props {
  milestones: MilestoneIndicator__MilestoneFragment[];
}

export const TeamGantishMilestoneIndicator = ({ milestones }: Props) => {
  if (milestones.length === 0) return null;

  const isLoneMileStone = milestones.length === 1;

  const byStatus = Object.entries(
    groupBy(milestones, (m) => m.metadata.status)
  ).filter(([_status, milestones]) => milestones.length > 0);

  return (
    <Popover
      content={milestones.map((m) => (
        <div key={m.id}>{m.name}</div>
      ))}
      className="clickable"
      overlayStyle={{
        paddingLeft: 30,
        paddingRight: 30,
        maxWidth: 1200,
      }}
    >
      <div className="TeamGantishMilestoneIndicator">
        {byStatus.map(([status, milestones]) => (
          <div
            key={status}
            className={cx('TeamGantishMilestoneIndicator__status', {
              'TeamGantishMilestoneIndicator__status--isLoneMileStone':
                isLoneMileStone,
              [`TeamGantishMilestoneIndicator__status--${status}`]: true,
            })}
          >
            <div className="TeamGantishMilestoneIndicator__count">
              {milestones.length}
            </div>
          </div>
        ))}
      </div>
    </Popover>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_INDICATOR__MILESTONES = gql`
  fragment MilestoneIndicator__Milestone on Milestone {
    id
    name
    metadata {
      status
    }
  }
`;
