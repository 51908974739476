import { Typography } from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';
import { DisplayName } from '../../../../../../components/DisplayName';
import { friendlyDate } from '../../../../../../services/dateFormats';
import { momentToDayjs } from '../../../../../../services/dateHelpers';
import './MitemHistoryEntry.less';

interface Props {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  timeStamp: Moment | string;
  user?: {
    name: string | null;
    displayName: string | null;
    email: string;
  } | null;
  footerOverride?: React.ReactNode;
}

export const MitemHistoryEntry = ({
  title,
  subTitle,
  timeStamp,
  user,
  footerOverride,
}: Props) => {
  const footer = footerOverride ? (
    footerOverride
  ) : user ? (
    <Trans i18nKey="MitemHistoryEntry.user">
      by
      <DisplayName user={user} />
    </Trans>
  ) : (
    ''
  );
  return (
    <div className="flx flx--column ">
      <div>
        <Typography.Text strong>{title}</Typography.Text>
      </div>
      <Typography.Text className="MitemHistoryEntry__subTitle">
        {subTitle}
      </Typography.Text>
      <Typography.Text
        type="secondary"
        style={{ fontSize: 12 }}
        className="flx"
      >
        {friendlyDate(momentToDayjs(timeStamp))}
        <div className="space--l">{footer}</div>
      </Typography.Text>
    </div>
  );
};
