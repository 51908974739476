import { Form, Modal } from 'antd';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../ErrorPage';
import { ManageInitiativeTeamsModalContent } from './ManageInitiativeTeamsModalContent';

interface Props {
  visible: boolean;
  initiativeId: string;
  onClose?: () => void;
}

export const ManageInitiativeTeamsModal = ({
  initiativeId,
  visible,
  onClose,
}: Props) => {
  const [form] = Form.useForm();

  const handleClose = () => {
    onClose?.();
    form.resetFields();
  };
  return (
    <Modal
      open={visible}
      footer={null}
      style={{ top: 20 }}
      onCancel={handleClose}
      destroyOnClose
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<ManageInitiativeTeamsModalContent.Skeleton />}>
          <ManageInitiativeTeamsModalContent initiativeId={initiativeId} />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
