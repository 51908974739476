import { Radio } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { InitiativeTag } from '../../../../../../components/initiative/InitiativeTag';
import Skeleton from 'react-loading-skeleton';
import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { gql, useQuery } from '@apollo/client';
import {
  MilestoneInputVars,
  Step2MilestoneSuggestionsDocument,
  Step2_InitiativeFragment,
} from '../../../../../../generated/graphql';
import { without } from '../../../../../../services/without';

interface Props {
  initiative: Step2_InitiativeFragment;
  inputContext: { tenantId?: string; teamId: string };
  metaTags: MilestoneInputVars;
  selectedMilestoneSuggestion: MilestoneSuggestion | null;
  onSelectMilestoneSuggestion: (milestone: MilestoneSuggestion) => void;
  onNextStep: () => void;
}

export const Step2 = ({
  initiative,
  inputContext,
  metaTags,
  selectedMilestoneSuggestion,
  onSelectMilestoneSuggestion,
  onNextStep,
}: Props) => {
  const wizard = useWizardNavigation();

  const { data, loading } = useQuery(Step2MilestoneSuggestionsDocument, {
    variables: {
      milestoneInput: metaTags,
      inputContext,
    },
  });

  const handleChange = (suggestion: MilestoneSuggestion) => {
    wizard.unlockStep(wizard.currentStepPath);
    onSelectMilestoneSuggestion(suggestion);
  };

  return (
    <div>
      <h3 className="mb">Choose area of improvement</h3>
      <div className="mb--xs">
        <span className="space--r">
          Below are a few AI-generated areas of improvement concerning:
        </span>
        <InitiativeTag tag={initiative.tag} />
      </div>
      <div className="font-size--sm mb--l">
        <span className="bold space--r ">Keywords used:</span>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          {Object.entries(without(metaTags, 'selectedInitiative')).map(
            ([type, value]) => (
              <div key={type}>
                <span className="bold txt--secondary">{type}</span>: {value}
              </div>
            )
          )}
        </div>
      </div>

      {loading ? (
        Array.from({ length: 4 }, (_, i) => (
          <div key={i} className="borderBottom mb--s pa--s">
            <h4>
              <Skeleton count={0.3} />
            </h4>
            <p className="font-size--sm">
              <Skeleton count={1.6} />
            </p>
          </div>
        ))
      ) : (
        <Radio.Group
          value={selectedMilestoneSuggestion}
          onChange={(e) => handleChange(e.target.value)}
        >
          {data?.milestoneSuggestions.milestones.map((suggestion, index) => (
            <div key={index} className="borderBottom mb--s pa--s">
              <Radio value={suggestion}>
                <h4 className="mb--xs">{suggestion.area}</h4>
                <p className="font-size--sm">
                  <span className="bold space--r">Definition of done:</span>
                  {suggestion.description}
                </p>
              </Radio>
            </div>
          ))}
        </Radio.Group>
      )}

      <div className="mt--l flx flx--jc-flx-end">
        <Btn type="link" onClick={wizard.goToPreviousStep}>
          Go back
        </Btn>
        <Btn
          type="primary"
          onClick={() => {
            onNextStep();
            wizard.goToNextStep();
          }}
          disabled={
            loading ||
            (!!wizard.activeStep.nextStep &&
              wizard.isLocked(wizard.activeStep.nextStep))
          }
        >
          Generate plan
        </Btn>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_INITIATIVES = gql`
  fragment Step2_Initiative on Initiative {
    id
    tag {
      colorCode
      iconId
      title
    }
  }
`;

export type MilestoneSuggestion = {
  area: string;
  description: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_ACTIVITY_SUGGESTIONS = gql`
  query Step2MilestoneSuggestions(
    $milestoneInput: MilestoneInputVars!
    $inputContext: InputContext
  ) {
    milestoneSuggestions(
      milestoneInput: $milestoneInput
      inputContext: $inputContext
    ) {
      milestones {
        id
        area
        description
      }
    }
  }
`;
