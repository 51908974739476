import {
  Checkbox,
  ConfigProvider,
  Form,
  FormInstance,
  Input,
  Select,
} from 'antd';
import { Btn } from '../../../../../../components/Button';
import TextArea from 'antd/es/input/TextArea';
import { friendlyUsername } from '../../../../../../services/friendlyUsername';
import { SprintDatepicker } from '../../../planning/components/SprintDatepicker';
import {
  AccelerationDay,
  Step4Demo_UserFragment,
} from '../../../../../../generated/graphql';
import { TagsSelector } from '../../../planning/components/MitemForm/TagsItem';
import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { SkaDraft } from './AiSuggestButton';
import { createRef, useMemo, useState } from 'react';
import { isArrayOfStrings } from '../../../../../../services/typeGuards';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import { gql } from '@apollo/client';
import { Colors } from '../../../../../componentLibrary/Colors';
import { useSkaValidationRules } from '../../../../common/utils/useSkaValidationRules';

interface Props {
  teamId: string;
  skaDrafts: SkaDraft[];
  onConfirmSelection: (activitySuggestionsIds: string[]) => void;
  onEditDraft: (draftId: string, field: Partial<SkaDraft>) => void;
  members: Step4Demo_UserFragment[];
  membersLoading: boolean;
}

export const Step4 = (props: Props) => {
  const wizard = useWizardNavigation();

  const [selectedDrafts, setSelectedDrafts] = useState(
    props.skaDrafts.map((a) => a._suggestionId)
  );

  const rulesFor = useSkaValidationRules();

  const formRefs = useMemo(() => {
    const refs = props.skaDrafts.map((_draft) =>
      createRef<FormInstance<SkaDraft>>()
    );
    return refs;
  }, [props.skaDrafts]);

  const goToConfirm = async () => {
    props.onConfirmSelection(selectedDrafts); // Updating which drafts are selected is fine even if validation fails

    const formValidation = await Promise.allSettled(
      formRefs.map((ref) => ref.current?.validateFields())
    );

    const allValid = formValidation.every(
      (result) => result.status === 'fulfilled'
    );

    if (allValid) {
      wizard.unlockStep(wizard.currentStepPath);
      wizard.goToNextStep();
    } else {
      wizard.lockStep(wizard.currentStepPath);
    }
  };

  const handleCheckboxClick: CheckboxGroupProps['onChange'] = (selected) => {
    if (!isArrayOfStrings(selected)) {
      throw new Error('Expected array of strings');
    }

    if (selected.length > 0) {
      wizard.unlockStep(wizard.currentStepPath);
    } else {
      wizard.lockStep(wizard.currentStepPath);
    }

    setSelectedDrafts(selected);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: Colors.Grays.SECONDARY_FONT,
            labelFontSize: 11,
            itemMarginBottom: 12,
            verticalLabelPadding: '0 0 4px 0',
          },
        },
      }}
    >
      <div>
        <h3 className="mb--l">Key activities to achieve plan</h3>
        <div>
          <Checkbox.Group
            onChange={handleCheckboxClick}
            value={selectedDrafts}
            className="d-block"
          >
            {props.skaDrafts.map((draft, index) => (
              <div className="borderBottom pa flx" key={draft._suggestionId}>
                <div className="mt--xl bold">
                  <Checkbox value={draft._suggestionId}>{index + 1}.</Checkbox>
                </div>
                <Form
                  ref={formRefs[index]}
                  className="pl flx--1"
                  name={draft._suggestionId}
                  onValuesChange={(changedValue) =>
                    props.onEditDraft(draft._suggestionId, changedValue)
                  }
                  initialValues={draft}
                  autoComplete="off"
                  layout="vertical"
                  requiredMark={'optional'}
                >
                  <Form.Item
                    name="name"
                    label="Title"
                    required
                    rules={rulesFor.name}
                  >
                    <Input></Input>
                  </Form.Item>
                  <Form.Item
                    name="definitionOfDone"
                    label="Definition of done"
                    required
                    rules={rulesFor.definitionOfDone}
                  >
                    <TextArea rows={3} />
                  </Form.Item>
                  <div className="flx">
                    <div>
                      <Form.Item
                        name="ownerId"
                        label="Owner"
                        required
                        rules={rulesFor.ownerId}
                      >
                        <Select
                          style={{ width: 180 }}
                          showSearch
                          loading={props.membersLoading}
                          placeholder="Select owner"
                          options={props.members.map((m) => ({
                            label: friendlyUsername(m),
                            value: m.id,
                          }))}
                          filterOption={(inputValue, option) =>
                            option?.label
                              .toLocaleLowerCase()
                              .includes(inputValue.toLocaleLowerCase()) ?? false
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="ml">
                      <Form.Item
                        name="deadline"
                        label="Deadline"
                        required
                        rules={rulesFor.deadline}
                      >
                        <SprintDatepicker
                          teamId={props.teamId}
                          accelerationDay={AccelerationDay.MONDAY}
                        />
                      </Form.Item>
                    </div>
                    <div className="ml flx--1">
                      <Form.Item name="tags" label="Tags">
                        <TagsSelector
                          teamId={props.teamId}
                          className="fullWidth"
                          placeholder="Select Tags"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </div>
            ))}
          </Checkbox.Group>
        </div>

        <div className="mt--l flx flx--jc-flx-end">
          <Btn type="link" onClick={wizard.goToPreviousStep}>
            Go back to plan generation
          </Btn>
          <Btn
            type="primary"
            onClick={goToConfirm}
            disabled={props.membersLoading}
          >
            Summary
          </Btn>
        </div>
      </div>
    </ConfigProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const USER_FRAGMENT = gql`
  fragment Step4Demo_User on User {
    id
    name
    displayName
    email
    initials
  }
`;
