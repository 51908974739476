import { gql } from '@apollo/client';
import { Select } from 'antd';
import { UserAvatar } from '../../../UserAvatar';
import { DisplayName } from '../../../DisplayName';
import { friendlyUsername } from '../../../../services/friendlyUsername';
import { SelectTenantUser_User2Fragment } from '../../../../generated/graphql';

interface Props {
  users: SelectTenantUser_User2Fragment[];
  value?: SelectTenantUser_User2Fragment;
  className?: string;
  loading?: boolean;
  onSelect?: (data: SelectTenantUser_User2Fragment | undefined) => void;
}

export const SelectTenantUser = ({
  users,
  value,
  loading,
  className,
  onSelect,
}: Props) => {
  return (
    <Select
      allowClear
      onClear={() => onSelect?.(undefined)}
      value={value?.id}
      className={className}
      loading={loading}
      disabled={loading}
      options={users.map((user) => ({
        value: user.id,
        label: (
          <div>
            <span className="mr--s">
              <UserAvatar user={user} size="small" />
            </span>

            {friendlyUsername(user)}
          </div>
        ),
        user,
      }))}
      style={{ width: 300 }}
      onSearch={(_) => onSelect?.(undefined)}
      onSelect={(_, option) => onSelect?.(option.user)}
      showSearch
      optionRender={(option) => {
        const user = option.data.user;
        return (
          <div className="flx">
            <span>
              <UserAvatar user={user} />
            </span>
            <div className="ml pr--s ellipsis">
              <DisplayName user={user} />
              <div className="font-size--sm txt--secondary ellipsis">
                {user.email}
              </div>
            </div>
          </div>
        );
      }}
      filterOption={(inputValue, option) => {
        if (!option) return false;
        return option.user.email
          .toLowerCase()
          .includes(inputValue.toLowerCase()) ||
          (option.user && option.user.name)
          ? option.user.name!.toLowerCase().includes(inputValue.toLowerCase())
          : false;
      }}
      // onSearch={(text) => setOptions(getPanelValue(text))}
      placeholder="Search user"
      notFoundContent={<div>No match</div>}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SELECT_TENANT_USER = gql`
  fragment SelectTenantUser_User2 on User2 {
    id
    name
    displayName
    email
    rev
    initials
    domainId {
      itemId
      tenantId
    }
  }
`;
