import {
  BaseInitiative,
  InitiativeNode,
  initiativeTreeBuilder,
} from '../../../../initiatives/InitiativesNavigation/initiativeTreeBuilder';

type TopInitiativeReportInput<T, U> = {
  initiative: T;
  allSubInitiatives: U[];
};

export interface GigantishData<T, U> {
  initiative: T | U;
}

export type GigantishNode<T, U> = InitiativeNode<GigantishData<T, U>>;

export function gigantishTreeBuilder<
  T extends BaseInitiative,
  U extends BaseInitiative,
>(
  initiatives: TopInitiativeReportInput<T, U>[]
): InitiativeNode<GigantishData<T, U>>[] {
  const initiativesTree: InitiativeNode<GigantishData<T, U>>[] =
    initiatives.map((i) => {
      const subInitiativeNodes: InitiativeNode<GigantishData<T, U>>[] =
        i.allSubInitiatives.map((si) => ({
          id: si.id,
          key: si.id,
          title: si.name,
          parentIsCompletedOrArchived: false,
          baseInitiative: si,
          data: {
            initiative: si,
          },
        }));

      return {
        id: i.initiative.domainId.itemId,
        key: i.initiative.domainId.itemId,
        title: i.initiative.name,
        baseInitiative: i.initiative,
        children: initiativeTreeBuilder(
          subInitiativeNodes,
          i.initiative.id,
          i.initiative.domainId.itemId,
          i.initiative.metadata.archived
        ),
        parentIsCompletedOrArchived: false,
        data: { initiative: i.initiative },
      };
    }) ?? [];

  return initiativesTree;
}
