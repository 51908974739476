import {
  ResponsiveContainer,
  LineChart,
  ReferenceLine,
  Line,
  XAxis,
  YAxis,
  DotProps,
  Tooltip,
  LineProps as BrokenLineProps,
} from 'recharts';
import { Empty } from 'antd';
import { CustomTooltip } from './CustomTooltip';
import { lineColor } from '../../styleVars';
import { isDefined } from '../../services/isDefined';
import { roundToDecimals } from '../../services/roundNumbersHelper';

type LineProps = Omit<BrokenLineProps, 'ref'>; // Don't know why but if we don't remove ref it fails to compile due to type missmatch

interface Period {
  label: string;
  targetDate: string;
  goal?: number | null;
  actual?: number | null;
}

export interface MigCompactGraphProps {
  lineData: Period[];
  activeDot?: number | null;
  referenceLine?: string;
  onClick?: (periodIndex: number) => void;
  height?: number;
  lineConf?: LineProps[];
  minY?: number | null;
  maxY?: number | null;
}

export const MigCompactGraph = ({
  lineData,
  onClick,
  referenceLine,
  activeDot,
  height = 130,
  lineConf,
  minY,
  maxY,
}: MigCompactGraphProps) => {
  if (lineData.length === 0)
    return (
      <div className="AkpiGraph__noDataWrapper">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );

  lineConf = lineConf ?? [
    { dataKey: 'goal', stroke: lineColor.goal, name: 'Goal' },
    { dataKey: 'actual', stroke: lineColor.actual, name: 'Actual' },
  ];

  const baseProps: Partial<LineProps> = {
    type: 'linear',
    isAnimationActive: false,
    strokeWidth: 2,
    dot: (props) => (
      <CustomDot
        {...props}
        activeDot={activeDot}
        referenceLine={referenceLine}
      />
    ),
  };

  return (
    <div style={{ width: '99%', height }}>
      <ResponsiveContainer debounce={300}>
        <LineChart
          data={lineData}
          onClick={(data) => {
            if (!isDefined(data.activeTooltipIndex) || !onClick) return;
            onClick(data.activeTooltipIndex);
          }}
          margin={{
            top: 12,
            bottom: 24,
            left: 0,
            right: 8,
          }}
        >
          <Tooltip
            formatter={(value) =>
              typeof value === 'number' ? roundToDecimals(value, 2) : value
            }
            labelFormatter={(label) =>
              lineData.find((p) => p.targetDate === label)?.label
            }
            itemStyle={{ padding: 0 }}
            content={(props) => <CustomTooltip {...props} />}
          />
          <XAxis
            dataKey="targetDate"
            interval={0}
            tickSize={0}
            tick={<CustomAxisTick lineData={lineData} />}
            minTickGap={5}
            height={2}
          />
          <YAxis
            domain={[minY ?? 'dataMin', maxY ?? 'dataMax']}
            tick={{ fontSize: 12 }}
            minTickGap={5}
            width={45}
          />
          <ReferenceLine
            x={referenceLine}
            stroke={lineColor.currentPeriod}
            strokeDasharray="3 3"
          />
          {lineConf.map((lineProps, i) => {
            const mergedProps = { ...baseProps, ...lineProps };
            return <Line key={i} {...mergedProps} />;
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

interface CustomDotProps extends DotProps {
  index: number;
  dataKey: string;
  payload: {
    targetDate: string;
  };
  stroke: string;
  referenceLine?: string;
  activeDot?: number | null;
}

const CustomDot = (props: CustomDotProps) => {
  if (!props.cx || !props.cy) return null;
  const isActiveDot = props.activeDot === props.index;
  const isOnReferenceLine = props.payload.targetDate === props.referenceLine;
  const radius = isOnReferenceLine || isActiveDot ? 5 : 2;

  const color = props.stroke;

  return (
    <circle
      r={radius}
      strokeWidth="1"
      stroke={color}
      fill={isActiveDot ? color : '#ffff'}
      className="recharts-dot recharts-line-dot"
      cx={props.cx}
      cy={props.cy}
    />
  );
};

interface CustomAxisTickProps {
  lineData: Period[];
  [key: string]: any; // a buch of props (e.g. x,y, index) are added by recharts (probably via cloneElement) and since we don't want to send them ourselves as props we cannot add them to the interface explicitly
}

const CustomAxisTick = (props: CustomAxisTickProps) => {
  const { x, y, index, lineData } = props;
  if (index === 0 || index === lineData.length - 1) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          fontSize="12"
          x={0}
          y={0}
          dy={16}
          textAnchor={index === 0 ? 'middle' : 'end'}
          fill="#888"
        >
          {lineData[props.index]?.label}
        </text>
      </g>
    );
  }
  return null;
};
