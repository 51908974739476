import { useState } from 'react';
import { Btn } from '../../../../../../components/Button';
import { Modal } from 'antd';
import { Step1 } from './Step1';
import { MilestoneSuggestion, Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import {
  ConfigStep,
  WizardNavigationProvider,
} from '../../../../../../components/WizardNavigationProvider';
import { AiWizard } from './AiWizard';
import { gql, useQuery } from '@apollo/client';
import {
  AiSuggestDemoButtonQueryDocument,
  AiWizardDemoInitiativesDocument,
  SprintKaInput,
} from '../../../../../../generated/graphql';
import { toMap } from '../../../../../../services/toMap';
import { SparklesIcon } from '../../../../../../icons/SparklesIcon';

interface Props {
  teamId: string;
}

const activitySuggestions = [
  {
    id: '1',
    headline: 'Supply Chain Optimization',
    description:
      'Centralized procurement processes lead to a 15% reduction in procurement costs through volume discounts and renegotiated supplier terms.',
  },
  {
    id: '2',
    headline: 'Streamline Vendor Management with Strategic Partnerships',
    description:
      'Vendor consolidation results in a 25% vendor reduction and improved cost savings through strategic partnerships.',
  },
  {
    id: '3',
    headline: 'Rationalize Fixed Costs Across Multiple Locations',
    description:
      'Rationalizing fixed costs yields a 20% reduction in office and vehicle expenses by eliminating redundancies.',
  },
  {
    id: '4',
    headline: 'Implement Comprehensive Training on Cost-Saving Policies',
    description:
      'Complete training on cost-saving policies within 6 months, ensuring full realization of intended savings and alignment with financial goals.',
  },
];

export interface SkaDraft extends SprintKaInput {
  _suggestionId: string;
}
export const AiSuggestDemoButton = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInitiativeId, setSelectedInitiative] = useState<string | null>(
    null
  );
  const [selectedMilestoneSuggestion, setSelectedMilestoneSuggestion] =
    useState<MilestoneSuggestion | null>(null);

  const [selectedActivitySuggestions, setSelectedActivitySuggestions] =
    useState(activitySuggestions.map((a) => a.id));

  const [_skaDrafts, setSkaDrafts] = useState<SkaDraft[]>(() =>
    activitySuggestions.map((a) => ({
      name: a.headline,
      definitionOfDone: a.description,
      tags: [],
      _suggestionId: a.id,
    }))
  );

  const skaDrafts = selectedInitiativeId
    ? _skaDrafts.map((draft) => ({
        ...draft,
        supportsInitiativeIds: [selectedInitiativeId],
      }))
    : _skaDrafts;

  // Todo: handle loading and error
  const { data } = useQuery(AiWizardDemoInitiativesDocument, {
    variables: {
      teamId: props.teamId,
    },
  });

  const baseInfo = useQuery(AiSuggestDemoButtonQueryDocument, {
    variables: { teamId: props.teamId },
  });

  const handleEdit = (draftId: string, field: Partial<SkaDraft>) => {
    setSkaDrafts((drafts) =>
      drafts.map((draft) =>
        draft._suggestionId === draftId ? { ...draft, ...field } : draft
      )
    );
  };

  const initiatives = data?.initiatives.initiatives ?? [];
  const selectedInitiative = initiatives.find(
    (init) => init.id === selectedInitiativeId
  );

  const handlesave = () => {
    setModalOpen(false);
  };

  const selectedSkaDrafts = skaDrafts.filter((draft) =>
    selectedActivitySuggestions.includes(draft._suggestionId)
  );

  const teamMembers = baseInfo.data?.membersV2.members.map((m) => m.user) ?? [];

  const memberLookup = toMap(teamMembers, (m) => m.id);

  const tagLookup = toMap(baseInfo.data?.teamTags.tags ?? [], (t) => t.id);

  const wizardConfig: ConfigStep[] = [
    {
      title: 'Select Initiative',
      type: 'ConfigComponentStep',
      key: 'SELECT_INITIATIVE',
      isMandatory: true,
      component: () => (
        <Step1
          initiatives={initiatives}
          selectedInitiative={selectedInitiativeId}
          onSelectInitiative={setSelectedInitiative}
        />
      ),
    },
    {
      title: 'Choose Milestone',
      type: 'ConfigComponentStep',
      key: 'SELECT_PROPOSITION',
      isMandatory: true,
      component: () =>
        selectedInitiative && (
          <Step2
            initiative={selectedInitiative}
            selectedMilestoneSuggestion={selectedMilestoneSuggestion}
            onSelectMilestoneSuggestion={setSelectedMilestoneSuggestion}
          />
        ),
    },
    {
      title: 'Select activities',
      type: 'ConfigComponentStep',
      key: 'SELECT_ACTIVITIES',
      isMandatory: false, // it is mandatory, but everything required is prefilled by default
      component: () =>
        selectedMilestoneSuggestion && (
          <Step3
            milestoneSuggestion={selectedMilestoneSuggestion}
            activitySuggestions={skaDrafts.map((draft) => ({
              headline: draft.name ?? '',
              description: draft.definitionOfDone ?? '',
              id: draft._suggestionId,
            }))}
            selectedActivitySuggestions={selectedActivitySuggestions}
            onSelectActivitySuggestions={setSelectedActivitySuggestions}
          />
        ),
    },
    {
      title: 'Refine plan',
      type: 'ConfigComponentStep',
      key: 'ACTIVITY_FORMS',
      isMandatory: true,
      component: () => (
        <Step4
          teamId={props.teamId}
          skaDrafts={selectedSkaDrafts}
          members={teamMembers}
          membersLoading={baseInfo.loading}
          onEditDraft={handleEdit}
          onConfirmSelection={setSelectedActivitySuggestions}
        />
      ),
    },
    {
      title: 'Confirm and save',
      type: 'ConfigComponentStep',
      key: 'SUMMARY',
      component: () =>
        selectedMilestoneSuggestion && (
          <Step5
            teamId={props.teamId}
            milestoneSuggestion={selectedMilestoneSuggestion}
            skaDrafts={selectedSkaDrafts}
            tags={tagLookup}
            members={memberLookup}
            onSave={handlesave}
          />
        ),
    },
  ];

  return (
    <div>
      <Btn
        icon={<SparklesIcon />}
        type="primary"
        className="ml--s"
        onClick={() => setModalOpen(true)}
      >
        Get suggestions
      </Btn>
      <WizardNavigationProvider config={wizardConfig}>
        <Modal
          title={<h2>AI-enabled planning</h2>}
          width={660}
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          footer={null}
        >
          <AiWizard />
        </Modal>
      </WizardNavigationProvider>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVEs = gql`
  query AiWizardDemoInitiatives($teamId: ID!) {
    initiatives(teamId: $teamId) {
      initiatives {
        ...Step1Demo_Initiative
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_MEMBERS_V2_HOOK = gql`
  query AiSuggestDemoButtonQuery($teamId: ID!) {
    membersV2(teamId: $teamId, filter: { archived: false }) {
      teamId
      members {
        teamId
        user {
          ...Step4_User
          ...Step5_User
        }
      }
    }
    teamTags(teamId: $teamId) {
      tags {
        ...Step5_TeamTag
      }
    }
  }
`;
