import { Select } from 'antd';
import { sprintStatus } from '../../../../../../sprint/common/components/mitemFilters/SprintFilter';
import { DisplayDateRange } from '../../../../../../../../components/DisplayDateRange';
import './SprintSelector.less';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { SprintSelector_MitemSprintResponseFragment } from '../../../../../../../../generated/graphql';
import { useTransition } from 'react';

export const SPRINT_SELECTOR_FRAGMENT = gql`
  fragment SprintSelector_MitemSprintResponse on MitemSprintResponse {
    currentSprintIndex
    payload {
      id
      teamId
      startDate
      endDate
      locked
      finalized
    }
  }
`;

export type SelectedSprint = {
  id: string | null;
  startDate: string;
  endDate: string;
  finalized: boolean;
};

interface Props {
  value: SelectedSprint | null;
  onChange: (value: SelectedSprint | null) => void;
  sprints: SprintSelector_MitemSprintResponseFragment;
}

export const SprintSelector = ({ value, onChange, sprints }: Props) => {
  const [isPending, startTransition] = useTransition();

  const handleChange = (sprintIndex: number) => {
    if (sprintIndex === -1) return onChange(null);
    onChange(sprints.payload[sprintIndex]);
  };

  const { Option } = Select;
  const { t } = useTranslation();

  const selectedIndex = sprints.payload.findIndex((sp) => {
    const { startDate, endDate } = sp;
    return startDate === value?.startDate && endDate === value?.endDate;
  });

  return (
    <div data-intercom-target="Sprint selector">
      <Select
        style={{ width: 250 }}
        loading={isPending}
        value={selectedIndex}
        onChange={(selectedIndex) =>
          startTransition(() => {
            handleChange(selectedIndex);
          })
        }
      >
        <Option value={-1} className="MitemFilters__divider">
          {t('SprintSelector.showAll')}
        </Option>
        {sprints.payload.map((s, index) => {
          const sprintStatusClass = sprintStatus(
            sprints.currentSprintIndex ?? 0,
            index,
            s.locked
          );

          return (
            <Option
              key={index}
              value={index}
              className={`SprintSelector--${sprintStatusClass}`}
            >
              <div className="flx flx--ai-center">
                <div
                  className={`mr--s SprintSelector__sprintIndicator--${sprintStatusClass}`}
                ></div>
                <div>{`Sprint #${index + 1}`}</div>

                <DisplayDateRange
                  startDate={s.startDate}
                  endDate={s.endDate}
                  className="ml--s SprintSelector__dateRange"
                />
              </div>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};
