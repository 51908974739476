import { gql } from '@apollo/client';
import { useTeamPermissions } from '../../../../../../usePermissions';
import {
  Action,
  SprintKeyActivityDetails_MitemFragment,
} from '../../../../../../generated/graphql';
import { SkaDetailsContent } from '../../../../../team/sprint/planning/components/mitemPlanningBoard/skaDetails/SkaDetailsContent';
import { Spin, Tabs } from 'antd';
import { MitemHistory } from '../../../../../team/sprint/common/components/MitemHistory';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Btn } from '../../../../../../components/Button';
import { CloseOutlined } from '@ant-design/icons';
import './SprintKeyActivityDetails.less';
import { StatusTag } from '../../../../../../components/StatusTag';

interface Props {
  sprintKeyActivity: SprintKeyActivityDetails_MitemFragment;
  onClose: () => void;
}

export const SprintKeyActivityDetails = ({
  sprintKeyActivity,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { isAllowed, loading } = useTeamPermissions({
    teamId: sprintKeyActivity.teamId,
    requestedAction: {
      action: Action.READ,
      resource: 'sprintKA',
    },
  });

  const content = isAllowed ? (
    <>
      <Tabs
        size="small"
        tabBarStyle={{ fontSize: 12 }}
        items={[
          {
            key: '1',
            label: t('common.details'),
            children: <SkaDetailsContent fullSKa={sprintKeyActivity} />,
          },
          {
            key: '2',
            label: t('common.history'),
            disabled: loading || !isAllowed,
            children: (
              <div className="SkaDetails__tabContent pl--xs">
                <MitemHistory
                  teamId={sprintKeyActivity.teamId}
                  mitemId={sprintKeyActivity.id}
                />
              </div>
            ),
          },
        ]}
      />
    </>
  ) : (
    <SkaDetailsContent fullSKa={sprintKeyActivity} />
  );

  return (
    <div
      className={cx(
        'SprintKeyActivityDetails',
        `SprintKeyActivityDetails--${sprintKeyActivity.status}`
      )}
    >
      <Spin spinning={loading}>
        <div className="SprintKeyActivityDetails__header">
          <div className="SprintKeyActivityDetails__statusTagWrapper">
            <StatusTag status={sprintKeyActivity.status} />
          </div>

          <Btn
            shape="circle"
            type="text"
            onClick={onClose}
            className="SprintKeyActivityDetails__closeButton"
            icon={<CloseOutlined />}
          />
        </div>

        {content}
      </Spin>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_DETAILS_FRAGMENT = gql`
  fragment SprintKeyActivityDetails_Mitem on Mitem {
    id
    name
    teamId
    status
    ...SkaDetailsContent_Mitem
  }
`;
