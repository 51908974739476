import { Form, FormInstance, Input } from 'antd';
import { InitiativeContentFragment } from '../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import { Icons, InitiativeIcon } from '../InitiativeIcons';
import TextArea from 'antd/es/input/TextArea';
import { useMigForInitiative } from '../hooks/useMigsForInitiative';
import { MigSelector } from '../initiativeDrawer/MigSelector';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { TagColorBox } from '../../../../../components/initiative/ColorPicker';
import { Btn } from '../../../../../components/Button';
import cx from 'classnames';
import { InitiativeSelector } from '../../../../team/sprint/planning/components/MitemForm/InitiativeSelector';

interface Props {
  form: FormInstance<any>;
  initialValue: InitiativeContentFragment;
  initiativesLoading: boolean;
  initiatives: {
    id: string;
    completed: { value: boolean } | null;
    archived: boolean;
    tag: {
      title: string;
      colorCode: string;
      iconId: string;
    };
  }[];
}

export const EditSubInitiativeForm = ({
  form,
  initialValue,
  initiativesLoading,
  initiatives,
}: Props) => {
  const tagTitle = Form.useWatch('tagTitle', form) as string;
  const supportsInitiative = Form.useWatch(
    'supportsInitiative',
    form
  ) as string;
  const selectedMigIds = Form.useWatch('migs', form) as string[];
  const { t } = useTranslation();

  const selectedTeamId = initialValue.domainId.teamId;

  const { data } = useMigForInitiative(selectedTeamId);

  const formInitialValue = {
    name: initialValue?.name,
    tagTitle: initialValue?.tag.title,
    description: initialValue?.description,
    teamId: initialValue?.team.id,
    objectives: initialValue.objectives.map((o) => o.text),
    supportsInitiative: initialValue?.supportsInitiatives?.[0]?.id,
    migs: initialValue?.supportsMigs.map((m) => m.migV2.id),
  };

  const parentTag = initiatives.find((i) => i.id === supportsInitiative)?.tag;

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        initialValues={formInitialValue}
      >
        <h3>{initialValue?.tag.title}</h3>
        <Form.Item
          name="supportsInitiative"
          label={t('InitiativeForm.supportsInitiative')}
        >
          <InitiativeSelector
            initiatives={initiatives ?? []}
            loadingInitiatives={initiativesLoading}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('common.title')}
          rules={[
            {
              required: true,
              message: t('InitiativeForm.titleError'),
            },
            {
              min: 2,
              max: 51,
              message: t('InitiativeForm.titleError'),
            },
          ]}
        >
          <Input
            type="text"
            placeholder={t('InitiativeForm.titlePlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name="tagTitle"
          label={t('InitiativeForm.tagTitle')}
          rules={[
            {
              required: true,
              message: t('InitiativeForm.tagError'),
            },
            {
              min: 2,
              max: 20,
              message: t('InitiativeForm.tagError'),
            },
          ]}
        >
          <Input
            type="text"
            placeholder={t('InitiativeForm.tagTitlePlaceholder')}
            maxLength={21}
          />
        </Form.Item>
        <Form.Item label={t('InitiativeForm.tagPreview')}>
          <InitiativeTag
            title={tagTitle}
            borderColor={parentTag?.colorCode ?? TagColorBox.blue}
            icon={Icons[parentTag?.iconId as InitiativeIcon]}
            completed={initialValue?.completed?.value}
            archived={initialValue?.archived}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label={t('common.initiative.purpose')}
          rules={[
            {
              required: true,
              message: t('InitiativeForm.purposeError'),
            },
            {
              min: 2,
              max: 256,
              message: t('InitiativeForm.purposeError'),
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item label={t('common.initiative.objectives')} required={false}>
          <Form.List name="objectives">
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, index) => (
                  <div className="flx" key={field.key}>
                    <Form.Item
                      {...field}
                      className={cx('flx--1', {
                        mb: index === fields.length - 1,
                      })}
                    >
                      <Input />
                    </Form.Item>
                    <Btn
                      type="text"
                      icon={
                        <DeleteOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      }
                    />
                  </div>
                ))}
                <Btn
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    add();
                  }}
                >
                  {t('InitiativeForm.addObjective')}
                </Btn>
              </div>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item label="Migs">
          <Form.List name="migs">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div
                      className="flx"
                      key={field.key + (selectedTeamId ?? 'no-team-selected')}
                    >
                      <Form.Item
                        className={cx('flx--1', {
                          mb: index === fields.length - 1,
                        })}
                        {...field}
                      >
                        <MigSelector
                          selectedMigIds={selectedMigIds ?? []}
                          migs={data?.teamMigs.migs}
                        />
                      </Form.Item>
                      {index > 0 ? (
                        <Btn
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </div>
                  ))}
                  <Form.Item>
                    <Btn
                      type="link"
                      disabled={selectedTeamId === undefined}
                      icon={<PlusOutlined />}
                      onClick={() => {
                        add();
                      }}
                    >
                      {t('InitiativeForm.addMigAlignment')}
                    </Btn>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form.Item>
      </Form>
    </div>
  );
};
