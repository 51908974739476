import moment from 'moment';
import { Moment } from 'moment';
import { PublishStatus } from '../generated/graphql';

export const getStatusByDates = (
  startDate: Moment | string,
  endDate: Moment | string,
  today = moment()
) => {
  if (today.isBefore(startDate)) {
    return PublishStatus.UPCOMING;
  }
  if (moment(endDate).isBefore(today)) {
    return PublishStatus.ENDED;
  }
  return PublishStatus.ACTIVE;
};
