import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons';
import { Input, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccelerationMeetingOverviewTeam } from '../../../generated/graphql';
import { stringSort } from '../../../services/stringSort';
import { statusColor } from '../../../styleVars';
import './ActiveTeams.page.less';
import { TitleWithTooltip } from '../../team/setup/components/TitleWithTooltip';
import { contains } from '../../../services/contains';
import { Helmet } from 'react-helmet';
import { envConf } from '../../../envConf';
import { TourLink } from '../../../components/TourLink';
import { LearnMoreLink } from '../../../components/LearnMoreLink';
import moment from 'moment';
import { friendlyDate } from '../../../services/dateFormats';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { TeamDetailsDrawer } from './components/teamDetailsDrawer/TeamDetailsDrawer';
import { SummaryStat } from './components/summaryStat/SummaryStat';
import { useAccelerationMeetingOverview } from './hooks/useAccelerationMeetingOverview';

enum intercomProductTourId {
  SV = 2322,
  EN = 2321,
}

enum teamStatus {
  NOT_STARTED,
  ACTIVE,
  INACTIVE,
}

export const ActiveTeamsPage = () => {
  const { t } = useTranslation();
  const { INTERCOM_KEY } = envConf;

  const [showTeamDetailsForTeamId, setShowTeamDetails] = useState<
    string | null
  >(null);
  const [filterByName, setFilterByName] = useState<string>('');
  const { data, loading, error } = useAccelerationMeetingOverview();

  useEffect(() => {
    if (INTERCOM_KEY)
      (window as any).Intercom('update', {
        last_seen_on_acceleration_meeting_overview_page_at: Math.floor(
          Date.now() / 1000
        ),
      });
  }, [INTERCOM_KEY]);

  const teams = data?.accelerationMeetingOverview?.teams ?? [];

  const sortedTeams = [...teams].sort((teamA, teamB) => {
    const levelDelta = teamA.teamHierarchy.length - teamB.teamHierarchy.length;

    if (levelDelta !== 0) return levelDelta;
    return stringSort(teamA.name, teamB.name);
  });

  const filteredTeams = filterByName
    ? sortedTeams.filter((t) => contains(t.name, filterByName))
    : sortedTeams;

  const columns: ColumnProps<
    Omit<
      AccelerationMeetingOverviewTeam,
      'lastMeetingsDetails' | 'teamHierarchy' | 'team'
    >
  >[] = [
    {
      title: t('common.team'),
      dataIndex: 'name',
      sorter: (a, b) => stringSort(a.name, b.name),
      filters: teams.map((t) => ({ text: t.name, value: t.name })),
      filterSearch: true,
      onFilter: (value, team) =>
        typeof value === 'string' && team.name.includes(value),
      width: 300,
      render(teamName, { insightComment, id }, index) {
        const argsMap = insightComment.args?.reduce(
          (acc, { name, value }) => ({
            ...acc,
            [name]: value,
          }),
          {} as Record<string, string>
        );
        return (
          <div
            data-intercom-target={`Active teams table team row #${index + 1}`}
          >
            <TeamDetailsDrawer
              teamId={id}
              showTeamDetails={id === showTeamDetailsForTeamId}
              setShowTeamDetails={() => {
                setShowTeamDetails(null);
              }}
              comment={t(
                `ActiveTeamsPage.insight.${insightComment.code}.short`,
                argsMap
              )}
              analysis={t(
                `ActiveTeamsPage.insight.${insightComment.code}.long`,
                argsMap
              )}
            />
            {teamName}
          </div>
        );
      },
    },
    {
      title: (
        <TitleWithTooltip
          title={
            <span data-intercom-target="Active teams table active header">
              {t('common.active')}
            </span>
          }
          tooltipContent={t('ActiveTeamsPage.tooltips.active')}
        ></TitleWithTooltip>
      ),
      dataIndex: 'active',
      sorter: (a, b) => Number(a.active) - Number(b.active),
      filters: [
        {
          text: t('common.active'),
          value: teamStatus.ACTIVE,
        },
        {
          text: t('ActiveTeamsPage.inactive'),
          value: teamStatus.INACTIVE,
        },
        {
          text: t('ActiveTeamsPage.yetToStart'),
          value: teamStatus.NOT_STARTED,
        },
      ],
      onFilter: (value, team) => {
        return value === teamStatus.ACTIVE
          ? team.active
          : value === teamStatus.NOT_STARTED
            ? team.neverStarted
            : !team.active && !team.neverStarted;
      },
      filterMultiple: false,
      render(active, team) {
        return active ? (
          <span>
            <CheckCircleFilled
              className="mr"
              style={{ color: statusColor.SUCCESS }}
            />
            {t('ActiveTeamsPage.yes')}{' '}
          </span>
        ) : !active && team.neverStarted ? (
          <span>
            <MinusCircleFilled
              className="mr"
              style={{ color: statusColor.NOT_STARTED }}
            />
            {t('ActiveTeamsPage.no')}
          </span>
        ) : (
          <span>
            <CloseCircleFilled
              className="mr"
              style={{ color: statusColor.FAILURE }}
            />
            {t('ActiveTeamsPage.no')}
          </span>
        );
      },
    },
    {
      title: (
        <span data-intercom-target="Active teams table mostRecentMeetingDate header">
          {t('ActiveTeamsPage.mostRecentMeetingDate')}
        </span>
      ),
      dataIndex: 'mostRecentMeetingDate',
      sorter: (a, b) =>
        stringSort(a.mostRecentMeetingDate, b.mostRecentMeetingDate),
      render(_, { mostRecentMeetingDate }) {
        if (!mostRecentMeetingDate)
          return <span>{t('ActiveTeamsPage.yetToStart')}</span>;
        return (
          <>
            <span title={friendlyDate(mostRecentMeetingDate)}>
              {moment(mostRecentMeetingDate).fromNow()}
            </span>
          </>
        );
      },
    },
    {
      title: (
        <TitleWithTooltip
          title={
            <span data-intercom-target="Active teams table insightComment header">
              {t('ActiveTeamsPage.insightComment')}
            </span>
          }
          tooltipContent={t('ActiveTeamsPage.tooltips.comment')}
        ></TitleWithTooltip>
      ),
      dataIndex: 'insightComment',
      width: '40%',
      sorter: (a, b) => a.completionPercent - b.completionPercent,
      render(_, { insightComment }) {
        const argsMap = insightComment.args?.reduce(
          (acc, { name, value }) => ({
            ...acc,
            [name]: value,
          }),
          {} as Record<string, string>
        );

        return (
          <div className="flx flx--jc-space-between ellipsis">
            {t(`ActiveTeamsPage.insight.${insightComment.code}.short`, argsMap)}
            <div className="ActiveTeamsPage__clickable mr">
              {t('ActiveTeamsPage.viewDetails')}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="content mt--l">
      <Helmet title={t('ActiveTeamsPage.accelerationMeetingOverviewTitle')} />

      <Typography.Title
        level={5}
        className="mb--none mt--none mr"
        style={{ display: 'inline-block' }}
      >
        {t('ActiveTeamsPage.shortDescription')}
      </Typography.Title>
      <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/28898-active-teams" />
      <TourLink
        intercomTarget="Active teams product tour button"
        engTourId={intercomProductTourId.EN}
        sweTourId={intercomProductTourId.SV}
      />
      <div className="flx mb--l mt--l">
        <SummaryStat
          label={t('ActiveTeamsPage.neverStarted')}
          value={
            <span
              className="txt--secondary"
              data-intercom-target="Never started teams non active team card"
            >
              {
                data?.accelerationMeetingOverview?.summary
                  .totalNeverStartedTeams
              }
            </span>
          }
          description={t('ActiveTeamsPage.neverStartedDescription')}
        />
        <SummaryStat
          label={t('ActiveTeamsPage.totalInactive')}
          value={
            <span
              className="txt--danger"
              data-intercom-target="Active teams non active team card"
            >
              {data?.accelerationMeetingOverview?.summary.totalInactiveTeams}
            </span>
          }
          description={t('ActiveTeamsPage.totalInactiveDescription')}
        />
        <SummaryStat
          label={t('ActiveTeamsPage.totalActive')}
          value={
            <span
              style={{ color: statusColor.SUCCESS }}
              data-intercom-target="Active teams active team card"
            >
              {data?.accelerationMeetingOverview?.summary.totalActiveTeams}
            </span>
          }
          description={t('ActiveTeamsPage.totalActiveDescription')}
        />
      </div>
      <Input.Search
        className="mb--s mt--l"
        data-testid="search-input"
        data-intercom-target="Active teams search by team name"
        placeholder={t('ActiveTeamsPage.filterByTeamName')}
        onChange={(e) => {
          return setFilterByName(e.currentTarget.value);
        }}
        style={{ width: 300 }}
      />
      <ErrorAlert error={error}></ErrorAlert>
      <Table
        dataSource={filteredTeams}
        data-intercom-target="Active teams table"
        columns={columns}
        showSorterTooltip={false}
        rowKey="id"
        rowClassName="ActiveTeamsPage__row"
        onRow={(record) => {
          return {
            onClick: () => {
              if (showTeamDetailsForTeamId === null)
                setShowTeamDetails(record.id);
            },
          };
        }}
        loading={loading}
        style={{ maxWidth: 1080 }}
      />
    </div>
  );
};
